import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

import { SocialLink } from "components/dover/SocialLink";
import { Chip } from "components/library/Chip";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";
import { SocialLinkCellProps } from "views/candidates/CandidateTable/table/cells/types";

export const NameCell = ({ candidate, socialLinkTypes }: SocialLinkCellProps): React.ReactElement => {
  const currentJobTitle: string = candidate.contact.headline ?? "";
  // fairly arbitrary truncation for now. eventually this can be done better
  const shouldTruncate = currentJobTitle.length >= 50;

  const fullName = candidate.contact.fullName;

  const isSampleCandidate = fullName === "Max Kolysh";

  // Choose the first social link of each type.
  // This is a stopgap to match pre-existing behavior where we deduped social links by type on the backend.
  const socialLinksByType = _.groupBy(candidate.contact?.socialLinks ?? [], socialLink => socialLink.linkType);
  const socialLinks = Object.values(socialLinksByType)
    .map(links => links?.[0])
    .filter(socialLink => socialLinkTypes?.includes(socialLink.linkType) ?? true);

  return (
    <Cell display="flex" alignItems="center" minWidth="200px">
      <Box width="100%">
        {candidate.contact && (
          <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall weight="500">{fullName}</BodySmall>
            {socialLinks.map(socialLink => (
              <span onClick={(e): void => e.stopPropagation()}>
                <SocialLink linkType={socialLink.linkType} url={socialLink.url} />
              </span>
            ))}
          </Stack>
        )}
        {currentJobTitle && !isSampleCandidate && (
          <>
            <Spacer height={2} />
            {shouldTruncate ? (
              <Tooltip title={currentJobTitle} variant={TooltipVariant.Dark} placement="bottom">
                <JobTitle color={colors.grayscale.gray500}>{currentJobTitle}</JobTitle>
              </Tooltip>
            ) : (
              <JobTitle color={colors.grayscale.gray500}>{currentJobTitle}</JobTitle>
            )}
          </>
        )}
        {isSampleCandidate && (
          <>
            <Spacer height={8} />
            <Chip variant="Informational" label={"Sample Candidate - use Queue to Send"} />
          </>
        )}
      </Box>
    </Cell>
  );
};

const JobTitle = styled(BodySmall)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
