import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";

import { CalendlyRole } from "App/components/Onboarding/AccountDetailsFormV2";
import { DoverLoginSource, doverLoginSourceAtom } from "App/routing/atoms";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ScheduleOnboardingOptionalFirstUserSVG } from "assets/images/schedule-onboarding-optional-first-user.svg";
import { ReactComponent as ScheduleOnboardingJoinExistingSVG } from "assets/images/schedule-onboarding-optional-join-existing.svg";
import { ReactComponent as ScheduleOnboardingSVG } from "assets/images/schedule-onboarding.svg";
import { PRODUCT_CALENDLY, SALES_CALENDLY } from "components/constants";
import DoverLogo from "components/icons/DoverLogo";
import { Button, ButtonVariant } from "components/library/Button";
import DoverLoadingOverlay from "components/loading-overlay";
import { CalendlyEmbed } from "components/onboarding/CalendlyEmbed";
import { useGetAuthedUserInfoQuery, useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { useAuth0 } from "services/react-auth0-spa";
import { colors, screenSizesNumbers } from "styles/theme";

export const ScheduleOnboarding = (): React.ReactElement => {
  const muiTheme = useTheme();
  const isLaptopL = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptopL));
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptop));
  const navigate = useNavigate();

  const { user } = useAuth0();
  const { data: userInfo, isFetching: userFetching } = useGetAuthedUserInfoQuery();
  const { data: usersList, isFetching: listUsersFetching } = useListProUsersForClientQuery({});
  const isInitialUser = usersList?.ids && usersList.ids.length === 1;

  const loginSourceValue = useAtomValue(doverLoginSourceAtom);

  if (userFetching || listUsersFetching) {
    return <DoverLoadingOverlay active={true} />;
  }

  const roleTitleData = userInfo?.roleTitleData as any;
  const isFounder = roleTitleData ? roleTitleData["calendlyRole"] === CalendlyRole.Founder : false;
  const isReferral = roleTitleData ? roleTitleData["isReferral"] === "true" : false;

  const isPotentialOpportunity = isFounder || isReferral;
  const calendlyUrl = isPotentialOpportunity ? SALES_CALENDLY : PRODUCT_CALENDLY;

  const onScheduledOrSkip = (): void => {
    // users that originate from the extension should be redirected to the extension create job flow
    if (loginSourceValue === DoverLoginSource.Extension) {
      navigate({ pathname: APP_ROUTE_PATHS.job.extensionCreateJob() });
    } else {
      navigate({ pathname: APP_ROUTE_PATHS.home() });
    }
  };

  const calendlyWidget = (
    <CalendlyEmbed
      calendlyUrl={calendlyUrl}
      name={userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : user.name}
      email={user.email}
      utmCampaign="schedule_onboarding_call"
      utmMedium="app"
      utmSource="onboarding_flow"
      onEventScheduled={onScheduledOrSkip}
      // these fixed dimensions are a bit weird, but because the embed is an iframe
      // it is hard to style, these are optimized for the most common screen sizes
      styles={{
        height: "100%",
        maxHeight: "700px",
        width: "100%",
        marginTop: isLaptopL ? "-30px" : undefined,
      }}
      dateAndTimeSelectedMaxHeight="800px"
    />
  );

  return (
    <Stack direction="row" height={"100%"} overflow="hidden" justifyContent="flex-start">
      <Box
        px={isSmallScreen ? 2 : 4}
        width={isSmallScreen ? "100%" : "35%"}
        borderRight={`solid 1px ${colors.grayscale.gray200}`}
        height="100%"
        sx={{ overflowX: "hidden", overflowY: "auto", position: "relative" }}
      >
        <Box
          position="sticky"
          top={0}
          left={0}
          zIndex={100}
          sx={{ backgroundColor: "white", paddingBottom: "0px !important" }}
          p={2}
          ml="-16px"
        >
          <DoverLogo width={72} />
        </Box>

        <Stack
          spacing={0}
          height={isSmallScreen ? "110%" : "94%"}
          justifyContent={isSmallScreen ? "flex-start" : "center"}
          alignItems={"center"}
        >
          <Stack maxWidth={"700px"} height={isSmallScreen ? "40%" : "100%"}>
            {isPotentialOpportunity && (
              <ScheduleOnboardingSVG width={isSmallScreen ? "85%" : "100%"} height={isSmallScreen ? "100%" : "100%"} />
            )}
            {!isPotentialOpportunity && isInitialUser && (
              <ScheduleOnboardingOptionalFirstUserSVG
                width={isSmallScreen ? "85%" : "100%"}
                height={isSmallScreen ? "100%" : "150%"}
              />
            )}
            {!isPotentialOpportunity && !isInitialUser && (
              <ScheduleOnboardingJoinExistingSVG
                width={isSmallScreen ? "85%" : "100%"}
                height={isSmallScreen ? "100%" : "150%"}
              />
            )}
          </Stack>
          {isSmallScreen && (
            <>
              {calendlyWidget}
              <Button removePadding variant={ButtonVariant.Ghost} onClick={onScheduledOrSkip}>
                Skip
              </Button>
            </>
          )}
        </Stack>
      </Box>
      {!isSmallScreen && (
        <Box
          flexGrow={1}
          borderRight={`solid 1px ${colors.grayscale.gray200}`}
          height="100%"
          sx={{
            overflowX: "hidden",
            overflowY: "auto",
            position: "relative",
            backgroundColor: colors.grayscale.gray100,
          }}
          width="50%"
          minWidth="560px"
        >
          <Stack height="100%" alignItems="center" justifyContent={isLaptopL ? "flex-start" : "center"} overflow="auto">
            {calendlyWidget}
            <Stack pb={4}>
              <Button removePadding variant={ButtonVariant.Ghost} onClick={onScheduledOrSkip}>
                Skip
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};
