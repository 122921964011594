//////////////////////////
// Shared cache tag ids //
//////////////////////////

// Useful as a cache tag id for entities that only exist in the UI and have yet to be persisted to the backend.
export const FAKE_ID = "FAKE_ID";

// Useful as a cache tag id for list endpoints
export const LIST_TAG = "LIST";

/////////////////////
// Cache tag types //
/////////////////////
export const JOB = "job";
export const JOB_CANDIDATE_SOURCE_SETTING = "jobCandidateSourceSetting";
export const JOB_BOARDS = "jobBoards";
export const JOB_PITCH = "jobPitch";
export const JOB_ATS_STAGES = "jobAtsStages";
export const USER_CREDENTIAL = "userCredential";
export const NOTIFICATION_CONFIG = "notificationConfig";
export const PRO_USER = "proUser";
export const CHANNEL_PERSONA_STATS_BENCHMARK = "channelPersonaStatsBenchmark";
export const CAMPAIGN = "campaign";
export const CAMPAIGN_MESSAGE_REQUEST = "campaignMessageRequest";
export const CANDIDATE_SOURCE = "candidateSource";
export const JOB_DESCRIPTION = "jobDescription";
export const JOB_SETUP = "jobSetup";
export const APPLICATION_PORTAL_JOB = "applicationPortalJob";
export const JOB_INTERVIEWERS_SCHEDULING_INFO = "jobInterviewersSchedulingInfo";
export const INTERVIEWER_SCHEDULING_INFO = "interviewerSchedulingInfo";
export const DOVER_INTERVIEWER_CANDIDATES = "interviewerSchedulingChange";
export const CLIENT = "client";
export const RECRUITING_PARTNER = "recruitingPartner";
export const CLIENT_ONBOARDING = "clientOnboarding";
export const CLIENT_ROLES = "clientRoles";
export const USER_UPLOADED_FILE = "userUploadedFile";
export const SEARCH_CRITERIA = "searchCriteria";
export const JOB_FULFILLMENT = "jobFulfillment";
export const CANDIDATE_BIO = "candidateBio";
export const HIRING_STAGE_EMAIL_TEMPLATE = "hiringStageEmailTemplateChange";
export const GENERIC_EMAIL_RESPONSE_TEMPLATE = "genericEmailResponseTemplateChange";
export const CANDIDATE_EMAIL_EVENT = "candidateEmailEvent";
export const GET_EMAIL_TEMPLATE_V2 = "getEmailTemplateV2";
export const CANDIDATE_INTERVIEW_EVENT = "candidateInterviewEvent";
export const CANDIDATE_JOB_APPLICATION_EVENT = "candidateJobApplicationEvent";
export const CANDIDATE_STAGE_CHANGE_EVENT = "candidateStageChangeEvent";
export const CANDIDATE_NOTE = "candidateNotesChange";
export const APPLICATION_QUESTION = "applicationQuestion";
export const CANDIDATE_MOVED_JOB_EVENT = "candidateMovedJobEvent";
export const PIPELINE_CANDIDATE = "pipelineCandidate";
export const PIPELINE_CANDIDATE_COUNTS = "pipelineCandidateCounts";
export const CANDIDATES_NEXT_ACTIONS = "candidatesNextActions";
export const MANUAL_SOURCING_CONTEXT = "manualSourcingContext";
export const JOB_FEATURE_SETTINGS = "jobFeatureSettings";
export const GREENHOUSE_DATA = "greenhouseData";
export const ASHBY_DATA = "ashbyData";
export const LEVER_DATA = "leverData";
export const LEVER_AUTH_INFO = "leverAuthInfo";
export const JOB_SETUP_STEP = "jobSetupStep";
export const PROFILE_ATTRIBUTE = "profileAttribute";
export const USER_RATING = "userRating";
export const USER_RATINGS_BY_ENTITY = "userRatingsByEntity";
export const HIRING_PIPELINE_STAGE = "hiringPipelineStage";
export const INTERVIEWER = "interviewer";
export const APPLICATION_REVIEW_CANDIDATE_DETAILS = "applicationReviewCandidateDetails";
export const SAAP_REVIEW_SCORED_APPLICATION_LIST = "saapReviewScoredApplicationList";
export const STARRED_CANDIDATE_LIST = "starredCandidateList";
export const USER_ONBOARDING_FLOW = "userOnboardingFlow";
export const CURRENT_ONBOARDING_STEP = "currentOnboardingStep";
export const SEARCH_V3 = "searchv3";
export const EMAIL_SENDER_ALIAS = "emailSenderAlias";
export const HIRING_PLAN_JOB_POSITION = "hiringPlanJobPosition";
export const CANDIDATE_INTERVIEW_RUBRIC_RESPONSE = "candidateInterviewResponse";
export const LIST_FEEDBACK_FORMS_FOR_INTERVIEW = "listFeedbackFormsForInterview";
export const SCHOOL_LIST = "schoolList";
export const COMPANY_LIST = "companyList";
export const JOB_ATS_SOURCE_SETTINGS = "jobAtsSourceSettings";
export const JOB_ATS_SOURCES = "jobAtsSources";
export const GMAIL_AUTH_CHECK = "gmailAuthCheck";
export const CANDIDATE_INTERVIEW = "candidateInterview";
export const SEARCH_V3_CHANGE_LOG = "searchv3ChangeLog";
export const PERSONAS_V2 = "personasV2";
export const TITLE_PATTERNS = "titlePatterns";
export const PATTERNS_FOR_PERSONA = "patternsForPersona";
export const CAREERS_PAGE_JOB = "careersPageJob";
export const CAREERS_PAGE_JOB_COUNT = "careersPageJobCount";
export const CANDIDATE_FILE_LIST = "candidateFileList";
export const EDIT_CAREERS_PAGE_JOB = "editCareersPageJob";
export const CANDIDATE_INTERVIEW_TRANSCRIPT = "candidateInterviewTranscript";
export const CLIENT_EMAIL_TEMPLATE = "clientEmailTemplate";
export const FEEDBACK_TEMPLATE = "feedbackTemplate";
export const LIST_TALENT_NETWORK = "listTalentNetwork";
export const LIST_CONTACT_EMAILS = "listContactEmails";
export const LIST_CONTACT_PHONE_NUMBERS = "listContactPhoneNumbers";
export const FIND_DUPE_CONTACT = "findDupeContact";
export const FIND_RELATED_CANDIDATES = "findRelatedCandidates";
export const LIST_REFERRERS = "listReferrers";
