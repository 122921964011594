import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React, { FC, useState } from "react";

import { ClickableFileViewer } from "components/FileViewer";
import { useHotkey } from "hooks/useHotkey";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { SaapReviewApplication } from "services/openapi";
import { ActionSection } from "views/candidates/ApplicationReview/components/MainPanel/ActionSection";
import { ApplicantInfo } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/ApplicantInfo";
import { ApplicationAnswersTab } from "views/candidates/ApplicationReview/components/MainPanel/ApplicationAnswersTab";
import { ExplanationTab } from "views/candidates/ApplicationReview/components/MainPanel/ExplanationTab";
import { MainContentTabs, Tabs } from "views/candidates/ApplicationReview/components/MainPanel/MainContentTabs";
import { NotesTab } from "views/candidates/ApplicationReview/components/MainPanel/NotesTab";
import { shouldShowRpUpsellAtom } from "views/candidates/ApplicationReview/hooks/useSaapNumActioned";
import BioLoadingErrorState from "views/candidates/components/BioLoadingErrorState";
import ErrorFetchingAppsState from "views/candidates/components/ErrorFetchingAppsState";
import NoApplicantsEmptyState from "views/candidates/components/NoApplicantsEmptyState";
import RecruitingPartnerUpsell from "views/candidates/components/RecruitingPartnerUpsell";

// This is used for the keyboard shortcut to move through these tabs
// The order should match the display order - maybe would be nice to refactor this to be codified in future
const tabs: Array<Tabs> = ["Resume", "Application", "Notes", "Explanation"];

interface MainPanelProps {
  application?: SaapReviewApplication;
  isFetching: boolean;
  errorFetchingApps?: boolean;
  noResults?: boolean;
  // whether somehow the search params are broken and we should display an error message
  paramsAreBroken?: boolean;
}

export const MainPanel: FC<MainPanelProps> = ({
  application,
  isFetching,
  errorFetchingApps,
  noResults,
  paramsAreBroken,
}) => {
  /*
    This is some legacy code that we can maybe eventually remove.
    In the current state applicants will always have a candidate object and therefore candidate id.
    But in the past we lazily created candidates and so they didn't always have a candidate object.
    So we would use the inboundAppId as the candidateId in that case and the backend would figure it out.
    Any new applicants created since ~November 2023 don't need this logic, but it's here for any legacy applications
    from before that project.
  */
  const candidateId = application?.candidateId || application?.inboundAppId;

  const { currentData: candidateBio, isFetching: bioFetching } = useGetCandidateBioQuery(candidateId || skipToken); // Would prefer to use ?? but at the time of this writing the backend returns empty string here sometimes

  const [selectedTab, setSelectedTab] = useState<Tabs>("Resume");

  const isFreeCustomer = useIsBasePlanCustomer();
  const showUpsell = useAtomValue(shouldShowRpUpsellAtom);

  const nextTab = (): void => {
    const idx = tabs.indexOf(selectedTab);
    const nextIdx = idx === tabs.length - 1 ? 0 : idx + 1;

    setSelectedTab(tabs[nextIdx]);
  };

  const prevTab = (): void => {
    const idx = tabs.indexOf(selectedTab);
    const nextIdx = idx === 0 ? tabs.length - 1 : idx - 1;

    setSelectedTab(tabs[nextIdx]);
  };

  useHotkey("left,p", prevTab, "Go to previous tab");
  useHotkey("right,n", nextTab, "Go to next tab");

  // block on isFetching as that's the application list
  // don't block on bioFetching so we can show the ad while bio is loading
  if (!isFetching && isFreeCustomer && showUpsell) {
    return <RecruitingPartnerUpsell />;
  }

  if (isFetching || bioFetching) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Progress text="Loading Applicant Info..." size="large" />
      </Box>
    );
  }

  if (errorFetchingApps || paramsAreBroken) {
    return <ErrorFetchingAppsState paramsAreBroken={paramsAreBroken} />;
  }

  if (noResults) {
    // want to display a different message depending on whether we're in chat mode, because different action is required.
    return <NoApplicantsEmptyState />;
  }

  if (!candidateBio || !application) {
    return <BioLoadingErrorState />;
  }

  return (
    <Stack p="8px" spacing={1} sx={{ overflowY: "auto" }}>
      <ApplicantInfo candidateBio={candidateBio} />
      <ActionSection application={application} candidateBio={candidateBio} />
      <MainContentTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === "Resume" && (
        <ClickableFileViewer
          url={application?.resumePath}
          iframeHeight="900px"
          iframeContainerHeight="90vh"
          noFileText="No resume provided"
        />
      )}
      {selectedTab === "Application" && <ApplicationAnswersTab application={application} />}
      {selectedTab === "Notes" && <NotesTab candidateId={candidateId} />}
      {selectedTab === "Explanation" && <ExplanationTab applicationId={application?.inboundAppId} />}
    </Stack>
  );
};
