import { z } from "zod";

import { JobSetupPersona } from "services/openapi";

const trueOrFalseFormSchema = z.union([z.literal("true"), z.literal("false")], { required_error: "Required" });

export type TrueOrFalseFormType = z.infer<typeof trueOrFalseFormSchema>;

export const accountDetailsFormSchema = z.object({
  firstName: z.string().min(1, { message: "Required" }),
  lastName: z.string().min(1, { message: "Required" }),
  calendlyRole: z.string().min(1, { message: "Required" }),
  title: z.string().min(1, { message: "Required" }),
  region: z.string().min(1, { message: "Required" }),
  companyName: z.string().min(1, { message: "Required" }),
  isAgency: trueOrFalseFormSchema,
  isCustomerReferral: trueOrFalseFormSchema,
  referralSource: z
    .string()
    .min(1, { message: "Required" })
    .max(150, { message: "Must be less than 150 characters" }),
  lookingToGet: z.string(),
});

export type AccountDetailsFormSchemaType = z.infer<typeof accountDetailsFormSchema>;

export interface OnboardingFormProps {
  onSaveAndNext: () => void;
  onBack?: () => void;
}

export interface JobSetupPersonaWithCustomTitle extends JobSetupPersona {
  jobTitle: string;
}
