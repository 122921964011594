import { Box } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "react-use";
import { BooleanParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Centered } from "components/Centered";
import { DoverLoadingSpinner } from "components/loading-overlay";
import PageHelmet from "components/PageHelmet";
import { Role, useHasRole } from "components/RBAC";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useAutofillInterviewRubricResponseWithAIMutation } from "services/doverapi/endpoints/candidateInterview";
import {
  useSubmitInterviewRubricResponseMutation,
  useUpdateInterviewRubricResponseMutation,
} from "services/doverapi/endpoints/candidateInterview";
import {
  interviewRubricModeAtom,
  interviewRubricResponseStateAtom,
  setInterviewRubricModeToEditAtom,
} from "views/interview/CandidateInterview/atoms";
import { useInterviewRubricResponseQuery } from "views/interview/CandidateInterview/hooks";
import { InterviewRubricMode } from "views/interview/CandidateInterview/types";
import { AINoteFormatterDrawer, ROUGH_NOTES_DRAWER_NAME } from "views/interview/common/components/AINoteFormatter";
import { AISuggestedQuestionsDrawer } from "views/interview/common/components/AISuggestedQuestions";
import InterviewForm from "views/interview/common/components/InterviewForm";
import RestrictedAccess from "views/RestrictedAccess";

const CandidateInterview = React.memo(
  (): React.ReactElement => {
    // Hooks
    const navigate = useNavigate();
    const isDoverInterviewer = useHasRole(Role.INTERVIEWER);

    // url params
    const { candidateId } = useParams<{ candidateId: string }>();
    const { interviewRubricResponseId } = useParams<{ interviewRubricResponseId: string }>();

    // feature flags
    const useAISuggestedQuestions = useFeatureFlag(FeatureFlag.AISuggestedQuestions);
    const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);

    // internal state
    // This is a workaround to prevent the UI from visually re-rendering while we wait to initialize the feedback template
    // on the IRR. We need to wait to refetch the IRR to access the updated compiled form schema which is set on the BE
    const [isInitializingFeedbackTemplate, setIsInitializingFeedbackTemplate] = useBoolean(true);

    // jotai state
    const setInterviewRubricResponseState = useSetAtom(interviewRubricResponseStateAtom);
    const [interviewRubricModeAtomValue, setInterviewRubricModeAtomValue] = useAtom(interviewRubricModeAtom);
    const setInterviewRubricModeToEdit = useSetAtom(setInterviewRubricModeToEditAtom);

    // since tip tap editor is only semi-controlled, and we unmount and remount the editor after an API call,
    // we need to store the INITIAL notes in state to pass to the drawer between unmounts
    // we achieve this by storing overrideInitialNotes and setting it when we hit submit on the "autoFillNotes".
    const [overrideInitialNotes, setOverrideInitialNotes] = useState<string | undefined>(undefined);

    // Custom hook to fetch the interview rubric response
    const {
      interviewRubricResponse: candidateInterviewRubricResponse,
      rubricMode,
      isLoadingInterviewRubricResponse,
      isLoadingInterviewRubricResponseSuccess,
      error,
    } = useInterviewRubricResponseQuery({ candidateId, interviewRubricResponseId });

    // Interview Rubric Response Mutations (all different versions of partialUpdate)
    // Note (davin): ideally we refactor this to be a single mutation with differing params for each use case
    const [updateInterviewRubricResponse] = useUpdateInterviewRubricResponseMutation({
      fixedCacheKey: "updating-irr",
    });
    const [submitInterviewRubricResponse] = useSubmitInterviewRubricResponseMutation({
      fixedCacheKey: "submitting-irr",
    });

    // AI Notes Formatter
    const [aiNoteDrawerOpen, setAINoteDrawerOpen] = useQueryParam(ROUGH_NOTES_DRAWER_NAME, BooleanParam);
    const closeAIAutofillDrawer = (): void => setAINoteDrawerOpen(false);
    const [
      autofillRubricResponse,
      { isLoading: isAIAutofillingRubric },
    ] = useAutofillInterviewRubricResponseWithAIMutation();

    const submitAIAutofillNotes = async (updatedNotes: string): Promise<void> => {
      if (!updatedNotes || !interviewRubricResponseId || !candidateId) {
        return;
      }
      setOverrideInitialNotes(updatedNotes);
      await autofillRubricResponse({
        interviewRubricResponseId,
        roughNotes: updatedNotes,
        candidateId,
      }).unwrap();
      closeAIAutofillDrawer();
    };

    const job = useMemo(() => {
      return candidateInterviewRubricResponse?.candidate?.job;
    }, [candidateInterviewRubricResponse]);

    const slackChannelId = job?.slackChannelId;

    useEffect(() => {
      const updateFeedbackTemplateOnIRR = async (feedbackTemplateId: string): Promise<void> => {
        if (!candidateInterviewRubricResponse || !candidateInterviewRubricResponse.id) {
          setIsInitializingFeedbackTemplate(false);
          return;
        }
        await updateInterviewRubricResponse({
          rubricResponses: candidateInterviewRubricResponse.rubricResponses,
          interviewRubricResponseId: candidateInterviewRubricResponse.id,
          feedbackTemplate: feedbackTemplateId,
        }).then(() => {
          setIsInitializingFeedbackTemplate(false);
        });
      };

      if (!candidateInterviewRubricResponse) {
        return;
      }

      // ONLY IF the rubric is not yet IN_PROGRESS (state < 200), initialize selected feedback template with the following logic:
      const rubricState = candidateInterviewRubricResponse.interviewState;
      let feedbackTemplateToAssign: string | undefined = undefined;

      // don't use if (rubricState) because 0 value is falsy but valid in this case
      if (rubricState !== undefined && rubricState < 200) {
        // First check if a feedbackTemplate is already set on the IRR
        if (candidateInterviewRubricResponse.feedbackTemplate) {
          setIsInitializingFeedbackTemplate(false);
          return;
        }
        // Else use the default feedback template from the interview stage if it exists
        else if (candidateInterviewRubricResponse.candidateInterviewInfo?.defaultFeedbackTemplate) {
          feedbackTemplateToAssign = candidateInterviewRubricResponse.candidateInterviewInfo.defaultFeedbackTemplate;
        }
        // Else fallback to the default feedback template for the client
        else if (candidateInterviewRubricResponse.clientDefaultFeedbackTemplate) {
          // Update the feedback template on the IRR
          feedbackTemplateToAssign = candidateInterviewRubricResponse.clientDefaultFeedbackTemplate;
        }
      }
      // Once the rubric is IN_PROGRESS we will reference the feedback template from the IRR if it exists and no-op needed here

      if (feedbackTemplateToAssign) {
        updateFeedbackTemplateOnIRR(feedbackTemplateToAssign);
      } else {
        setIsInitializingFeedbackTemplate(false);
      }
    }, [candidateInterviewRubricResponse, setIsInitializingFeedbackTemplate, updateInterviewRubricResponse]);

    // Initialize Jotai state
    useEffect(() => {
      if (rubricMode) {
        setInterviewRubricModeAtomValue(rubricMode);
      }
    }, [rubricMode, setInterviewRubricModeAtomValue]);

    useEffect(() => {
      if (candidateInterviewRubricResponse?.interviewState) {
        setInterviewRubricResponseState(candidateInterviewRubricResponse.interviewState);
      }
    }, [candidateInterviewRubricResponse?.interviewState, setInterviewRubricResponseState]);

    // Explicitly invoked with the Save button
    const onSubmitInterviewForm = React.useCallback(
      async (v: any) => {
        await submitInterviewRubricResponse({
          interviewRubricResponseId: candidateInterviewRubricResponse?.id!!,
          rubricResponses: v,
        });
        // set interview rubric mode for instant UI feedback (instead of waiting for rubricMode to update from invalidated API response)
        if (!isDoverInterviewer) {
          setInterviewRubricModeAtomValue(InterviewRubricMode.Review);
        }
      },
      [
        candidateInterviewRubricResponse?.id,
        isDoverInterviewer,
        setInterviewRubricModeAtomValue,
        submitInterviewRubricResponse,
      ]
    );

    // Invoked automatically with autosave
    const onSaveInterviewForm = React.useCallback(
      async (v: any) => {
        // if currently pending, set to in progress, otherwise don't pass through state value
        const updatedState = candidateInterviewRubricResponse?.interviewState === 100 ? 200 : undefined;
        setInterviewRubricResponseState(updatedState);
        await updateInterviewRubricResponse({
          interviewRubricResponseId: candidateInterviewRubricResponse?.id!!,
          rubricResponses: v,
          interviewState: updatedState,
        });
      },
      [
        candidateInterviewRubricResponse?.id,
        candidateInterviewRubricResponse?.interviewState,
        setInterviewRubricResponseState,
        updateInterviewRubricResponse,
      ]
    );

    const interviewFormLoading =
      // if auto fill is still loading, then dont yet mount the response components so that the select components mount appropriately
      isAIAutofillingRubric ||
      // @ts-ignore
      !!candidateInterviewRubricResponse?.rubricResponses?.isAutoFillLoading ||
      !!isLoadingInterviewRubricResponse ||
      !candidateInterviewRubricResponse?.compiledJobInterviewRubricSchema ||
      isInitializingFeedbackTemplate;

    // Error states
    if (!isLoadingInterviewRubricResponse && !isLoadingInterviewRubricResponseSuccess) {
      // @ts-ignore
      if (error?.serializedError?.status === 403) {
        return (
          <Centered>
            <RestrictedAccess />
          </Centered>
        );
      }

      if (candidateId) {
        navigate(APP_ROUTE_PATHS.candidates.candidateDetail(candidateId));
        return <></>;
      }

      return (
        <Box pt={8} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <>
            <Box pb={2}>{"We couldn't find this interview."}</Box>
            <Box>
              {"If you think there's a mistake please contact Dover Support Team in "}
              {slackChannelId ? (
                <a
                  href={`https://dovertalent.slack.com/archives/${slackChannelId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Slack
                </a>
              ) : (
                " Slack"
              )}
              {" or "}
              <a href="mailto:support@dover.com?Subject=Candidate%20Interview%20Issue" target="_top">
                support@dover.com
              </a>
            </Box>
          </>
        </Box>
      );
    }

    const candidateName = candidateInterviewRubricResponse?.candidate?.contact?.fullName;

    const jobName = job?.title;

    const inReadOnlyMode = interviewRubricModeAtomValue === InterviewRubricMode.Review;

    // Render the IRR
    return (
      <>
        <PageHelmet title={`${candidateName || ""} - ${jobName || ""} Interview`} />
        {interviewFormLoading ? (
          <DoverLoadingSpinner />
        ) : (
          <InterviewForm
            disabled={!candidateInterviewRubricResponse || inReadOnlyMode}
            onSubmit={onSubmitInterviewForm}
            onSave={onSaveInterviewForm}
            onEdit={setInterviewRubricModeToEdit}
            schema={candidateInterviewRubricResponse?.compiledJobInterviewRubricSchema || {}}
            initialValues={candidateInterviewRubricResponse?.rubricResponses}
            candidate={candidateInterviewRubricResponse?.candidate}
            jobId={candidateInterviewRubricResponse?.candidate?.job?.id}
            clientId={candidateInterviewRubricResponse?.candidate?.job?.client?.id}
            job={candidateInterviewRubricResponse?.candidate?.job}
            jobLocations={candidateInterviewRubricResponse?.locations}
            jobCompensation={candidateInterviewRubricResponse?.compensation}
            visaSupport={candidateInterviewRubricResponse?.visaSupport}
            interviewInfo={candidateInterviewRubricResponse?.interview}
            candidateInterviewInfo={candidateInterviewRubricResponse?.candidateInterviewInfo}
            nextStepsInfo={candidateInterviewRubricResponse?.nextStepsInfo}
            numTimesRescheduled={candidateInterviewRubricResponse?.numTimesRescheduled}
            submittedByProUserId={candidateInterviewRubricResponse?.submittedByProUserId}
            interviewRubricResponseId={interviewRubricResponseId}
            existingRoughNotesAvailable={!!candidateInterviewRubricResponse?.roughNotes}
          />
        )}
        {!useAINotetaker && (
          <>
            <AINoteFormatterDrawer
              drawerOpen={!!aiNoteDrawerOpen}
              onClose={closeAIAutofillDrawer}
              initialNotes={overrideInitialNotes || candidateInterviewRubricResponse?.roughNotes || ""}
              onSubmit={submitAIAutofillNotes}
              isSubmitting={isAIAutofillingRubric}
            />

            {interviewRubricResponseId && useAISuggestedQuestions && (
              <AISuggestedQuestionsDrawer interviewRubricResponseId={interviewRubricResponseId} />
            )}
          </>
        )}
      </>
    );
  }
);

export default CandidateInterview;
