import { Checkbox, Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import HelpIconSVG from "assets/icons/help-question.svg";
import { StyledHelpIconSVG } from "components/dover/CompanySetupBasicInfo/styles";
import { FormSchema, KeyObject } from "components/dover/CompanySetupBasicInfo/types";
import { getLinkedInIdFromUrl, getLinkedInUrlFromId } from "components/dover/CompanySetupBasicInfo/utils";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { getOpenApiClients } from "services/api";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  useBulkUpsertClientDomainsMutation,
  useListClientDomainsQuery,
  useUpdateClientOnboardingMutation,
} from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { ClientDomain } from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

interface CompanySetupBasicInfoProps {
  hideSaveButton?: boolean;
  onPrimaryDomainIndexChange?: (newIndex: number) => void;
}

const CompanySetupBasicInfo = ({
  hideSaveButton,
  onPrimaryDomainIndexChange,
}: CompanySetupBasicInfoProps): React.ReactElement => {
  const [newCompanyDomainKeys, setNewCompanyDomainKeys] = React.useState<KeyObject[]>([]);
  const [primaryDomainIndex, setPrimaryDomainIndex] = React.useState<number | undefined>(undefined);
  const [additionalErrors, setAdditionalErrors] = React.useState<Record<string, string>>({});

  const clientId = useGetClientId();
  const { data: clientOnboarding } = useGetClientOnboardingQuery();

  const [
    updateClientOnboardingMutation,
    { isLoading: clientOnboardingIsUpdating },
  ] = useUpdateClientOnboardingMutation();

  const { data: clientDomains } = useListClientDomainsQuery({});
  const [bulkUpsertClientDomains, { isLoading: clientDomainsAreUpdating }] = useBulkUpsertClientDomainsMutation();

  const {
    register,
    unregister,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const isAdmin = useHasRole(Role.ADMIN);
  const isClientAdmin = useHasRole(Role.CLIENT_ADMIN);

  const hasPermissionsToExportCSV = isAdmin || isClientAdmin;
  const exportCsvEnabledForClient = useFeatureFlag(FeatureFlag.ExportCandidateCSV);

  React.useEffect(() => {
    if (clientDomains !== undefined) {
      if (clientDomains.length > 0) {
        clientDomains.forEach((domain: ClientDomain, index: number) => {
          if (domain.isPrimaryDomain) {
            setPrimaryDomainIndex(index);
            onPrimaryDomainIndexChange?.(index);
          }
        });
      } else {
        // If no domains, then add a new one automatically
        setNewCompanyDomainKeys([{ key: uuidv4() }]);
        setPrimaryDomainIndex(0);
        onPrimaryDomainIndexChange?.(0);
      }
    }

    if (clientOnboarding?.linkedinCompanyId) {
      setValue("linkedInUrl", getLinkedInUrlFromId(clientOnboarding.linkedinCompanyId));
    }

    if (clientOnboarding?.name) {
      setValue("companyName", clientOnboarding.name);
    }
  }, [clientDomains, clientOnboarding, onPrimaryDomainIndexChange, setValue]);

  const existingCompanyDomains = React.useMemo(() => {
    return clientDomains ?? [];
  }, [clientDomains]);

  const tryGetDuplicateDomainKey = React.useCallback(
    (newDomainsAsRecord: Record<string, string>) => {
      const existingDomainValues = existingCompanyDomains.map(existingDomain => existingDomain.domain);
      let errorKey: string | undefined = undefined;

      Object.entries(newDomainsAsRecord).forEach(([key, domain]) => {
        if (existingDomainValues.includes(domain)) {
          errorKey = key;
        }
      });

      return errorKey;
    },
    [existingCompanyDomains]
  );

  const onSaveBasicInfo = React.useCallback(
    (data: FormSchema) => {
      const trySaveBasicInfo = async (): Promise<void> => {
        const newDomainsAsRecord = getValues("newCompanyDomains");

        let duplicateDomainKey = undefined;
        // Only check for duplicate domains if new domains were added
        if (newDomainsAsRecord !== undefined) {
          duplicateDomainKey = tryGetDuplicateDomainKey(newDomainsAsRecord);
        }
        // If we have duplicate domains, short-circuit here
        if (duplicateDomainKey) {
          setAdditionalErrors({ [duplicateDomainKey]: "Duplicate domains not allowed" });
          return;
        } else {
          setAdditionalErrors({});
        }

        const newDomains: ClientDomain[] = [];
        if (newDomainsAsRecord) {
          Object.entries(newDomainsAsRecord).forEach(([, domain]) => {
            newDomains.push({ domain, isPrimaryDomain: false });
          });
        }

        const allDomains = existingCompanyDomains.concat(newDomains);
        const domains: Array<string> = [];
        for (const domain of allDomains) {
          domains.push(domain.domain);
        }

        const { id: linkedinCompanyId, error: linkedInError } = getLinkedInIdFromUrl(data.linkedInUrl);

        if (linkedInError) {
          setAdditionalErrors(prev => ({ ...prev, linkedInUrl: linkedInError }));
          return;
        }

        await bulkUpsertClientDomains({
          domains,
          primaryDomain: allDomains[primaryDomainIndex ?? 0].domain,
        }).unwrap();

        await updateClientOnboardingMutation({
          id: clientId!,
          data: {
            name: data.companyName,
            linkedinCompanyId,
          },
          onboardingSection: OnboardingSection.BASIC_INFO,
        }).unwrap();

        // Remove now-duplicate fields
        setNewCompanyDomainKeys([]);
      };

      trySaveBasicInfo();
    },
    [
      updateClientOnboardingMutation,
      clientId,
      getValues,
      tryGetDuplicateDomainKey,
      existingCompanyDomains,
      bulkUpsertClientDomains,
      primaryDomainIndex,
    ]
  );

  const addCompanyDomain = React.useCallback(() => {
    const newDomainKeys = [...newCompanyDomainKeys, { key: uuidv4() }];
    setNewCompanyDomainKeys(newDomainKeys);
  }, [newCompanyDomainKeys]);

  const handleDeleteCompanyDomain = React.useCallback(
    (index: number, key: string) => {
      const newDomainKeys = [...newCompanyDomainKeys];
      const shouldResetPrimaryDomain = index === primaryDomainIndex;

      newDomainKeys.splice(index, 1);

      // If we deleted the primary domain, reset the 0th domain to be primary for now
      if (shouldResetPrimaryDomain) {
        setPrimaryDomainIndex(0);
      }

      setNewCompanyDomainKeys(newDomainKeys);

      unregister(`newCompanyDomains.${key}` as any);
    },
    [newCompanyDomainKeys, primaryDomainIndex, unregister]
  );

  const getIsPrimaryDomain = React.useCallback(
    (index: number) => {
      return primaryDomainIndex !== undefined && primaryDomainIndex === index;
    },
    [primaryDomainIndex]
  );

  const exportDataAsCSV = React.useCallback(async () => {
    if (!clientOnboarding?.id) {
      return;
    }
    showPendingToast("Exporting your data...");

    const { apiApi } = await getOpenApiClients({});
    const response = await apiApi.exportDataAsCSV({ id: clientOnboarding.id });

    if (response.success) {
      showSuccessToast(response.message);
    } else {
      showErrorToast(response.message);
    }
  }, [clientOnboarding]);

  const checkBoxText = "Use the domain above as the primary domain to share with candidates";

  const shouldShowDeleteIcons = React.useMemo(() => {
    return existingCompanyDomains.length > 0 || newCompanyDomainKeys.length > 1;
  }, [existingCompanyDomains.length, newCompanyDomainKeys.length]);

  const getCheckBox = React.useCallback(
    (index: number) => {
      return (
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Checkbox
            sx={{
              pl: "0px",
              color: colors.grayscale.gray400,
              "&.Mui-checked": {
                color: colors.primary.base,
              },
            }}
            checked={getIsPrimaryDomain(index)}
            onChange={(): void => {
              setPrimaryDomainIndex(index);
            }}
          />
          <BodySmall>{checkBoxText}</BodySmall>
        </Stack>
      );
    },
    [getIsPrimaryDomain]
  );

  const companyDomainFields = React.useMemo(() => {
    return (
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Body weight="500">{"Company Domains"}</Body>
          <Tooltip
            title={"You can log into Dover from any of the following domains."}
            placement="top"
            arrow={true}
            variant={TooltipVariant.Dark}
            boxSizing="content-box"
          >
            {/* Span is necessary to prevent a MUI v5 render issue */}
            <span>
              <StyledHelpIconSVG src={HelpIconSVG} />
            </span>
          </Tooltip>
        </Stack>
        {existingCompanyDomains.map((domain: ClientDomain, index: number) => {
          return (
            <Stack>
              <TextField text={domain.domain} startAdornment={"@"} disabled={true} />
              {getCheckBox(index)}
            </Stack>
          );
        })}
        {newCompanyDomainKeys.map((domain: KeyObject, index: number) => {
          return (
            <Stack key={domain.key}>
              <TextField
                required={true}
                startAdornment={"@"}
                placeholderText={"E.g. dover.io"}
                errorMessage={errors["newCompanyDomains"]?.[domain.key]?.message ?? additionalErrors[domain.key]}
                error={!!errors["newCompanyDomains"]?.[domain.key] || !!additionalErrors[domain.key]}
                onDelete={
                  shouldShowDeleteIcons
                    ? (): void => {
                        handleDeleteCompanyDomain(index, domain.key);
                      }
                    : undefined
                }
                inputProps={{ ...register(`newCompanyDomains.${domain.key}` as any) }}
              />
              {getCheckBox(index + existingCompanyDomains.length)}
            </Stack>
          );
        })}
        <Stack direction="row" justifyContent="flex-start">
          <Button variant={ButtonVariant.Ghost} onClick={addCompanyDomain}>
            <Body weight="500">+Add Domain</Body>
          </Button>
        </Stack>
      </Stack>
    );
  }, [
    existingCompanyDomains,
    newCompanyDomainKeys,
    addCompanyDomain,
    getCheckBox,
    errors,
    additionalErrors,
    shouldShowDeleteIcons,
    register,
    handleDeleteCompanyDomain,
  ]);

  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <TextField
          title={"Company Name"}
          required={true}
          errorMessage={errors.companyName?.message}
          error={!!errors.companyName}
          inputProps={{ ...register("companyName") }}
        />
        {companyDomainFields}
        <TextField
          title={"Company LinkedIn Page"}
          required={true}
          errorMessage={errors.linkedInUrl?.message}
          error={!!errors.linkedInUrl}
          placeholderText={"e.g. https://www.linkedin.com/company/doverhq"}
          inputProps={{ ...register("linkedInUrl") }}
        />
      </Stack>
      {(exportCsvEnabledForClient || isAdmin) && (
        <Button
          width="206px"
          variant={ButtonVariant.Primary}
          onClick={exportDataAsCSV}
          // Disable if the user is not an admin or client admin
          disabled={!hasPermissionsToExportCSV}
          tooltip={
            hasPermissionsToExportCSV
              ? "Export your data as a CSV file. An email will be sent to your email (~3 minutes) containing results once job is complete."
              : "Looks like you don't have permission to download your data. Contact your admin for help"
          }
        >
          Export my data as CSV
        </Button>
      )}

      {!hideSaveButton && (
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <Button
            variant={ButtonVariant.Primary}
            onClick={handleSubmit(onSaveBasicInfo)}
            loading={clientOnboardingIsUpdating || clientDomainsAreUpdating}
          >
            <Body color="inherit" style={{ letterSpacing: "2px" }}>
              Save
            </Body>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CompanySetupBasicInfo;
