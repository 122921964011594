import { Stack } from "@mui/material";
import React from "react";
import { useBoolean } from "react-use";

import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { AntSwitch } from "components/StyledMuiComponents";
import { useJobId } from "hooks/useJobIdFromUrl";
import { usePartialUpdateCampaignMutation } from "services/doverapi/endpoints/campaign";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import { CampaignCampaignStateEnum, ListCampaign, ListCampaignStateEnum } from "services/openapi";
import { useIsCampaignEmailSenderAuthed } from "views/job/JobSetup/steps/CampaignVariants/hooks";

/*************************************************
 * Campaign State Change Modals
 ************************************************/

const ActivationModal: React.FC<{ campaign: ListCampaign; open: boolean; onClose: () => void }> = ({
  campaign,
  open,
  onClose,
}) => {
  const [jobId] = useJobId();

  const { data: userDefinedSenderUser } = useGetProUserQuery((campaign.userDefinedSenderUser as unknown) as string);

  const [partialUpdateCampaign, { isLoading }] = usePartialUpdateCampaignMutation();

  const handleActivateCampaign = async (): Promise<void> => {
    if (!jobId) {
      return;
    }

    await partialUpdateCampaign({
      id: campaign.id!,
      jobId: jobId,
      updatedCampaign: {
        name: campaign.name!,
        campaignState: CampaignCampaignStateEnum.Active,
      },
    }).unwrap();
  };

  return (
    <div
      onClick={(e): void => {
        e.stopPropagation();
      }}
    >
      <CustomModal
        open={open}
        onClose={onClose}
        title="Activate Campaign"
        maxWidth="sm"
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={onClose}>
              Cancel
            </Button>
            <Button variant={ButtonVariant.Primary} onClick={handleActivateCampaign} disabled={isLoading}>
              Activate
            </Button>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <BodySmall>Once you activate, new candidates will start receiving this outreach.</BodySmall>
          <ul>
            <li>
              <BodySmall>Remember to check for any typos.</BodySmall>
            </li>
            <li>
              <BodySmall>
                {userDefinedSenderUser
                  ? `Dover will send emails from ${userDefinedSenderUser.fullName}. Please make sure that the emails are addressed
              from this person.`
                  : "Please make sure that the emails are addressed from the correct sender."}
              </BodySmall>
            </li>
          </ul>
        </Stack>
      </CustomModal>
    </div>
  );
};

export const DeactivationModal: React.FC<{ campaign: ListCampaign; open: boolean; onClose: () => void }> = ({
  campaign,
  open,
  onClose,
}) => {
  const [jobId] = useJobId();

  const [partialUpdateCampaign, { isLoading }] = usePartialUpdateCampaignMutation();

  const [followUpEmailChecked, setFollowUpEmailChecked] = useBoolean(false);

  const handleDeactivateCampaign = async (): Promise<void> => {
    if (!jobId) {
      return;
    }

    await partialUpdateCampaign({
      id: campaign.id!,
      jobId: jobId,
      updatedCampaign: {
        name: campaign.name!,
        campaignState: CampaignCampaignStateEnum.Inactive,
        allowFollowUpsPostDeactivation: followUpEmailChecked,
      },
    }).unwrap();
  };

  return (
    <div
      onClick={(e): void => {
        e.stopPropagation();
      }}
    >
      <CustomModal
        open={open}
        onClose={onClose}
        title="Deactivate Campaign"
        maxWidth="sm"
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={onClose}>
              Cancel
            </Button>
            <Button variant={ButtonVariant.Critical} onClick={handleDeactivateCampaign} disabled={isLoading}>
              Deactivate
            </Button>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <BodySmall>
            No new candidates will be contacted with outreach from this campaign. You can indicate below if you would
            like existing candidates to be sent follow-ups.
          </BodySmall>
          <Stack direction="row" spacing={1}>
            <AntSwitch checked={followUpEmailChecked} onChange={setFollowUpEmailChecked} />
            <BodySmall>Send followup emails to existing candidates</BodySmall>
          </Stack>
        </Stack>
      </CustomModal>
    </div>
  );
};

/*************************************************
 * Campaign State Toggle Component
 ************************************************/

const CampaignStateToggle: React.FC<{ campaign: ListCampaign }> = ({ campaign }) => {
  const [openDeactivationModal, setOpenDeactivationModal] = useBoolean(false);
  const [openActivationModal, setOpenActivationModal] = useBoolean(false);

  const { isAuthed: isCampaignEmailSenderAuthed, isLoading: isLoadingEmailSenderAuth } = useIsCampaignEmailSenderAuthed(
    {
      userDefinedSenderUser: campaign.userDefinedSenderUser,
      emailSenderOption: campaign.emailSenderOption,
      emailAliasEmail: campaign.emailAlias?.email ?? undefined,
      emailAliasGmailCredentialAuthState: campaign.emailAlias?.gmailCredentialAuthState,
    }
  );

  const handleToggleCampaignState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.checked) {
      setOpenDeactivationModal(true);
    } else {
      setOpenActivationModal(true);
    }
  };

  const disableActivation =
    isLoadingEmailSenderAuth || (!isCampaignEmailSenderAuthed && campaign.state === ListCampaignStateEnum.Inactive);

  return (
    <>
      <ActivationModal campaign={campaign} open={openActivationModal} onClose={setOpenActivationModal} />
      <DeactivationModal campaign={campaign} open={openDeactivationModal} onClose={setOpenDeactivationModal} />

      <Tooltip title={disableActivation ? "Campaign must have an authenticated email sender to activate" : ""}>
        <div>
          <AntSwitch
            onClick={(e): void => {
              e.stopPropagation();
            }}
            checked={campaign.state === ListCampaignStateEnum.Active}
            onChange={handleToggleCampaignState}
            disabled={disableActivation}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default CampaignStateToggle;
