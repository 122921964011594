import styled from "styled-components";

import { colors } from "styles/theme";

export const ReferralsTableWrapper = styled.div`
  .MuiDataGrid-columnsContainer {
    background: ${colors.grayscale.gray100};
  }
  .h-cell {
    background: ${colors.grayscale.gray100};
  }
  & .MuiDataGrid-cell:focus,
  & .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  .MuiDataGrid-row {
    background: ${colors.white};
  }
  .MuiTablePagination-displayedRows {
    padding-top: 15px;
  }
`;
