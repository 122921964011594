import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { Body } from "components/library/typography";
import { useLocalStorage } from "hooks/useLocalStorage";
import { SubmitReferralFormSchemaType } from "views/ReferralForm/types";

interface ReferrerFormProps {
  name?: string;
  email?: string;
  onNext: () => void;
}

export const ReferrerForm = ({ name, email, onNext }: ReferrerFormProps): React.ReactElement => {
  const {
    register,
    formState: { errors: formErrors },
    setValue,
    getValues,
    trigger,
  } = useFormContext<SubmitReferralFormSchemaType>();

  const [, setSearchParams] = useSearchParams();
  const [savedName, setSavedName] = useLocalStorage("referrerName", "");
  const [savedEmail, setSavedEmail] = useLocalStorage("referrerEmail", "");

  // set initial values from query params
  useEffect(() => {
    if (name && !savedName) {
      setSavedName(name);
    }
    if (email && !savedEmail) {
      setSavedEmail(email);
    }
  }, [name, email, savedName, savedEmail, setSavedName, setSavedEmail]);

  useEffect(() => {
    if (savedEmail) {
      setValue("referrerEmail", savedEmail);
    }
    if (savedName) {
      setValue("referrerName", savedName);
    }
    if (savedEmail || savedName) {
      // clear out query params once we've set the values
      setSearchParams();
    }
  }, [savedName, savedEmail, setValue, setSearchParams]);

  const pageIsValid = async (): Promise<boolean> => {
    const isValid = await trigger(["referrerName", "referrerEmail"]);
    return isValid;
  };

  const handleNext = async (): Promise<void> => {
    setSavedName(getValues("referrerName"));
    setSavedEmail(getValues("referrerEmail"));

    if (await pageIsValid()) {
      onNext();
    }
  };

  return (
    <Card fullWidth>
      <Stack spacing={2}>
        <Body weight="600">Enter your information as the referrer</Body>
        <TextField
          title={`Name`}
          required={true}
          inputProps={{ ...register("referrerName") }}
          errorMessage={formErrors.referrerName?.message}
          error={!!formErrors.referrerName}
          autoFocus={!savedName}
        />
        <TextField
          title={`Email`}
          required={true}
          inputProps={{ ...register("referrerEmail") }}
          errorMessage={formErrors.referrerEmail?.message}
          error={!!formErrors.referrerEmail}
        />
        <Stack alignItems={"flex-end"}>
          <Button width="fit-content" variant={ButtonVariant.Primary} onClick={handleNext}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
