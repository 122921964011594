import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as XIcon } from "@doverhq/dover-ui/icons/x.svg";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useBoolean } from "react-use";

import Drawer from "components/library/Drawer";
import { Heading } from "components/library/typography";
import { UnsavedChangesModal } from "components/UnsavedChangesModal";
import { modalAtom, useModal } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { colors } from "styles/theme";
import EditCampaignWrapper from "views/job/JobSetup/steps/CampaignVariants/CampaignEditorV2";
import CreateCampaignForm from "views/job/JobSetup/steps/CampaignVariants/CreateCampaignForm";

interface DrawerHeaderProps {
  onClose: () => void;
}

export const DrawerHeader = ({ onClose }: DrawerHeaderProps): React.ReactElement => {
  const { context } = useModal(CampaignDrawerAtom);
  const campaignId = context?.campaignId;

  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "sticky",
        top: 0,
        height: "56px",
        zIndex: 1400,
        borderBottom: `1px solid ${colors.grayscale.gray200}`,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" height="100%" px={3} py={1}>
        <Heading>{campaignId ? "Edit" : "Create"} Campaign</Heading>
        <Box onClick={onClose} sx={{ cursor: "pointer" }}>
          <Icon Icon={XIcon} />
        </Box>
      </Stack>
    </Box>
  );
};

/* -----------------------------------------------------------------------------
 * Drawer Content
 * -------------------------------------------------------------------------- */
const CampaignDrawerContent: React.FC = () => {
  const { context } = useModal(CampaignDrawerAtom);
  const campaignId = context?.campaignId;

  const [newCampaignId, setNewCampaignId] = React.useState<string | undefined>();

  if (campaignId || newCampaignId) {
    // Edit mode
    return <EditCampaignWrapper campaignId={campaignId ?? newCampaignId!} />;
  }

  // Create mode
  return <CreateCampaignForm setNewCampaignId={setNewCampaignId} />;
};

/* -----------------------------------------------------------------------------
 * Main Component
 * -------------------------------------------------------------------------- */

interface CampaignDrawerProps {
  campaignId?: string;
}

const CampaignDrawer: React.FC<GlobalModalProps & CampaignDrawerProps> = ({ isOpen, close: onClose }) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useBoolean(false);

  const onDiscardUnsavedChanges = (): void => {
    setShowUnsavedChangesModal(false);
    onClose();
  };

  const validateOnClose = (): void => {
    setShowUnsavedChangesModal(true);
  };

  return (
    <>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onCancel={setShowUnsavedChangesModal}
        onDiscard={onDiscardUnsavedChanges}
      />
      <Drawer open={isOpen} onClose={validateOnClose} anchor="right" sx={{ zIndex: 1300 }}>
        <DrawerHeader onClose={validateOnClose} />
        <CampaignDrawerContent />
      </Drawer>
    </>
  );
};

export const CampaignDrawerAtom = modalAtom(CampaignDrawer);
