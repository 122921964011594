import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { LIST_REFERRERS } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiInviteReferrersRequest,
  ApiApiListReferrersRequest,
  InviteReferrerResponse,
  Referrer,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

interface ListReferrersResponse {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Referrer>;
}

const referralTabEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listReferrers: build.query<ListReferrersResponse, ApiApiListReferrersRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listReferrers(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to submit referral");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (_, __, args) => {
        return args.jobId ? [{ type: LIST_REFERRERS, id: args.jobId }] : [{ type: LIST_REFERRERS }];
      },
    }),
    inviteReferrers: build.mutation<InviteReferrerResponse, ApiApiInviteReferrersRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.inviteReferrers(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to invite referrers");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (_, __, args) => [{ type: LIST_REFERRERS, id: args.data.jobId }],
    }),
  }),
});

export const { useListReferrersQuery, useInviteReferrersMutation } = referralTabEndpoints;
