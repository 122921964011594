/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks*/

import { MenuItem, Select, Stack } from "@mui/material";
import { Mutator } from "final-form";
import React from "react";
import { Form } from "react-final-form";

import { DoverLoginSource } from "App/routing/atoms";
import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { ReactComponent as DoverDLogo } from "assets/logos/DoverD-in-circle.svg";
import { DoverInsights } from "components/dover/CreateJob/components/DoverInsights";
import ProUserSelector from "components/dover/inputs/pro-users/ProUserDropdownSelector";
import { EyeIcon, EyeCrossedOutIcon } from "components/icons/EyeIcon";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonRadio } from "components/library/ButtonRadio";
import { RadioButton } from "components/library/RadioButton";
import { TextField } from "components/library/TextField";
import { BodyExtraSmall, BodySmall, Subtitle2 } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { FormData } from "services/doverapi/endpoints/create-job/types";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

const setValue: Mutator<any> = ([name, newValue], state, { changeValue }) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  changeValue(state, name, _ => newValue);
};

interface FormErrors {
  hiringManagerId?: string;
  role?: string;
  title?: string;
}

const validate = (values: FormData): FormErrors => {
  let errors = {};
  if (!values.hiringManagerId) {
    errors = { ...errors, hiringManagertype: "This field is required" };
  }
  if (!values.title) {
    errors = { ...errors, title: "This field is required" };
  }

  return errors;
};

type RecruiterType = "Me" | "Another team member" | "CSM" | "Dover Recruiting Partner" | "CSM" | "";
interface RecruiterOption {
  type: RecruiterType;
  radioContent: React.ReactElement;
  secondaryContent?: React.ReactElement;
}

const CreateJobForm: React.FC<Props> = ({
  showHiringManagerAndRecruiterFields,
  onSubmit,
  doverLoginSource = DoverLoginSource.MainApp,
}: Props): React.ReactElement => {
  // form values
  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const { data: client } = useGetUsersClientQuery();

  const [title, setTitle] = React.useState<string | undefined>();
  const [hiringManagerId, setHiringManagerId] = React.useState<number | undefined>(userInfo?.id);
  const [recruiterId, setRecruiterId] = React.useState<number | undefined>(userInfo?.id);
  const [isPrivate, setIsPrivate] = React.useState<boolean>(false);
  const [hiringTimelineDays, setHiringPipelineDays] = React.useState<number | undefined>();
  const [page, setPage] = React.useState<number>(0);

  // UI State
  const [selectedRecruiterType, setSelectedRecruiterType] = React.useState<RecruiterType>("");

  const hoursPerWeek = hiringTimelineDays === 30 ? 24 : hiringTimelineDays === 60 ? 12 : 8;
  const timelineText = hiringTimelineDays === 30 ? "ASAP" : hiringTimelineDays === 60 ? "1-2 months" : "3+ months";

  const recruiterRadioOptions: Array<RecruiterOption> = [
    {
      type: "Me",
      radioContent: <BodySmall>Me</BodySmall>,
    },
    {
      type: "Another team member",
      radioContent: <BodySmall>Another team member</BodySmall>,
    },
  ];

  const csm = client?.csm?.proUser;
  if (csm?.firstName && csm?.firstName !== "George") {
    recruiterRadioOptions.push({
      type: "CSM",
      radioContent: (
        <Stack direction="row" spacing={0.3} alignItems="center">
          <DoverDLogo />
          <Stack direction="row" spacing={0.5}>
            <BodySmall>{csm.firstName}</BodySmall>
          </Stack>
        </Stack>
      ),
    });
  } else {
    recruiterRadioOptions.push({
      type: "Dover Recruiting Partner",
      radioContent: (
        <Stack direction="row" spacing={0.3} alignItems="center">
          <DoverDLogo />
          <Stack direction="row" spacing={0.5}>
            <BodySmall>Dover Recruiting Partner</BodySmall>
            <BodySmall color={colors.grayscale.gray600}>(select to book a call)</BodySmall>
          </Stack>
        </Stack>
      ),
    });
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        title,
        hiringManagerId,
        recruiterId,
        isPrivate,
        hiringTimelineDays,
      }}
      validate={(v): FormErrors => validate(v)}
      mutators={{ setValue }}
      render={({ handleSubmit, submitting, hasValidationErrors, errors }): React.ReactNode => {
        const page0Disabled = hasValidationErrors;
        const page1Disabled =
          selectedRecruiterType === "" ||
          (selectedRecruiterType === "Another team member" && !recruiterId) ||
          !hiringTimelineDays;
        const nextButtonDisabled = submitting || (page === 0 && page0Disabled) || (page === 1 && page1Disabled);

        return (
          <form onSubmit={handleSubmit} noValidate={true}>
            {page === 0 && (
              <Stack spacing={2.5} sx={{ pb: 2, marginTop: "0px !important" }}>
                <Stack spacing={1}>
                  <Subtitle2>Job title</Subtitle2>
                  <TextField
                    text={title ?? ""}
                    onTextUpdated={setTitle}
                    error={errors?.title && title !== undefined}
                    errorMessage="Required"
                    autoFocus
                  />
                </Stack>
                <Stack spacing={1} width="40%">
                  <Subtitle2>Hiring Manager</Subtitle2>
                  <ProUserSelector
                    value={hiringManagerId ?? null}
                    onChange={(value): void => setHiringManagerId(value as number)}
                    required={showHiringManagerAndRecruiterFields}
                    size="small"
                    fullWidth={false}
                  />
                </Stack>
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  <Subtitle2>Job Visibility</Subtitle2>
                  <ExternalLink
                    display="inline"
                    href={"https://help.dover.com/en/articles/8677668-user-permissions"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <HelpIcon color={colors.link} className="svg-color" />
                    </Stack>
                  </ExternalLink>
                </Stack>
                <Stack spacing={1} mt={"8px !important"} width="40%">
                  <Select
                    size="small"
                    value={isPrivate}
                    onChange={(e): void => {
                      setIsPrivate(e.target.value === "true");
                    }}
                    renderValue={(value): React.ReactNode => {
                      return (
                        <Stack direction="row" spacing={1} alignItems="center" pt="1px !important">
                          {value ? <EyeCrossedOutIcon /> : <EyeIcon />}
                          <BodySmall>{value ? "Private" : "Public"}</BodySmall>
                        </Stack>
                      );
                    }}
                  >
                    <MenuItem key={"public"} value={"false"}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <EyeIcon />
                        <BodySmall>Public</BodySmall>
                      </Stack>
                    </MenuItem>
                    <MenuItem key={"private"} value={"true"}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <EyeCrossedOutIcon />
                        <BodySmall>Private</BodySmall>
                      </Stack>
                    </MenuItem>
                  </Select>
                </Stack>

                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                  <Stack direction="row" spacing={1} justifyContent="end">
                    <Button
                      onClick={(): void => {
                        if (doverLoginSource === DoverLoginSource.Extension) {
                          // bypass second page if in extension context
                          // set self as recruiter always
                          setRecruiterId(userInfo?.id);
                          handleSubmit();
                        } else {
                          setPage(1);
                        }
                      }}
                      variant={ButtonVariant.Primary}
                      disabled={nextButtonDisabled}
                      tooltip={
                        errors?.title
                          ? "Job title is required"
                          : errors?.hiringManagerId
                          ? "Hiring Manager is required"
                          : undefined
                      }
                    >
                      <BodySmall color={nextButtonDisabled ? colors.grayscale.gray600 : colors.white}>
                        Continue
                      </BodySmall>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {page === 1 && (
              <Stack spacing={2.5} sx={{ pb: 2, marginTop: "0px !important" }}>
                <Stack spacing={1}>
                  <Subtitle2>When do you need to hire your {title}?</Subtitle2>
                  <Stack spacing={1} direction="row">
                    <ButtonRadio
                      active={hiringTimelineDays === 30}
                      onClick={(): void => setHiringPipelineDays(30)}
                      label="ASAP (1 month)"
                      variant="secondary"
                    />
                    <ButtonRadio
                      active={hiringTimelineDays === 60}
                      onClick={(): void => setHiringPipelineDays(60)}
                      label="1-2 Months"
                      variant="secondary"
                    />
                    <ButtonRadio
                      active={hiringTimelineDays === 90}
                      onClick={(): void => setHiringPipelineDays(90)}
                      label="3+ Months"
                      variant="secondary"
                    />
                  </Stack>
                </Stack>
                {!!hiringTimelineDays && <DoverInsights timelineText={timelineText} hoursPerWeek={hoursPerWeek} />}
                <Stack spacing={1}>
                  <Subtitle2>Who is the recruiter on this role?</Subtitle2>
                  <Stack spacing={1}>
                    {recruiterRadioOptions.map(option => {
                      const selected = selectedRecruiterType === option.type;
                      return (
                        <Stack spacing={0.5}>
                          <RadioButton
                            content={option.radioContent}
                            checked={selected}
                            onChange={(): void => {
                              if (option.type === "Me") {
                                setRecruiterId(userInfo?.id);
                              } else {
                                setRecruiterId(undefined);
                              }
                              setSelectedRecruiterType(option.type);
                            }}
                            value={selected}
                          />
                          {option.type === "Another team member" && selected && (
                            <Stack width="40%" pl={3}>
                              <ProUserSelector
                                value={recruiterId ?? null}
                                onChange={(value): void => setRecruiterId(value as number)}
                                required
                                size="small"
                                fullWidth={false}
                              />
                            </Stack>
                          )}
                          {option.type === "Dover Recruiting Partner" && (
                            <Stack paddingLeft={3.5} width="70%">
                              <BodyExtraSmall color={colors.grayscale.gray600}>
                                Recruiting partners bring extensive full-cycle recruiting experties, specializing in
                                sourcing, niche roles, salary negotiation, and reference checks (
                                <ExternalLink href="https://cdn.dover.io/dover/meet_the_team.mp4" target="_blank">
                                  meet the team
                                </ExternalLink>
                                )
                              </BodyExtraSmall>
                            </Stack>
                          )}
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>

                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                  <Stack direction="row" spacing={1} justifyContent="end">
                    <Button onClick={(): void => setPage(0)} variant={ButtonVariant.Secondary}>
                      <BodySmall color={colors.grayscale.gray600}>Back</BodySmall>
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant={ButtonVariant.Primary}
                      disabled={nextButtonDisabled}
                      tooltip={
                        nextButtonDisabled
                          ? !hiringTimelineDays
                            ? "Please select your goal hiring date"
                            : "Please select a recruiter"
                          : ""
                      }
                    >
                      <BodySmall color={nextButtonDisabled ? colors.grayscale.gray600 : colors.white}>
                        Add Job
                      </BodySmall>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </form>
        );
      }}
    />
  );
};

interface Props {
  creating: boolean;
  onSubmit: (values: FormData) => Promise<void> | void;
  initialTitle?: string;
  showHiringManagerAndRecruiterFields: boolean;
  showPersonaField?: boolean;
  iconOverride?: any;
  justifyHeaderCenter?: boolean;
  hideBackButton?: boolean;
  doverLoginSource?: DoverLoginSource;
}

export default CreateJobForm;
