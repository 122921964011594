/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExtensionAddCandidateResponse,
    ExtensionAddCandidateResponseFromJSON,
    ExtensionAddCandidateResponseToJSON,
    ExtensionCheckForCandidatesRequest,
    ExtensionCheckForCandidatesRequestFromJSON,
    ExtensionCheckForCandidatesRequestToJSON,
    ExtensionCheckForCandidatesResponse,
    ExtensionCheckForCandidatesResponseFromJSON,
    ExtensionCheckForCandidatesResponseToJSON,
    ExtensionFindEmailsRequest,
    ExtensionFindEmailsRequestFromJSON,
    ExtensionFindEmailsRequestToJSON,
    ExtensionFindEmailsResponse,
    ExtensionFindEmailsResponseFromJSON,
    ExtensionFindEmailsResponseToJSON,
    ExtensionGenerateOutreachRequest,
    ExtensionGenerateOutreachRequestFromJSON,
    ExtensionGenerateOutreachRequestToJSON,
    ExtensionGenerateOutreachResponse,
    ExtensionGenerateOutreachResponseFromJSON,
    ExtensionGenerateOutreachResponseToJSON,
    ExtensionListCampaignsForJobResponse,
    ExtensionListCampaignsForJobResponseFromJSON,
    ExtensionListCampaignsForJobResponseToJSON,
    ExtensionOutboxApproveRequest,
    ExtensionOutboxApproveRequestFromJSON,
    ExtensionOutboxApproveRequestToJSON,
    ExtensionOutboxApproveResponse,
    ExtensionOutboxApproveResponseFromJSON,
    ExtensionOutboxApproveResponseToJSON,
    ExtensionOutboxRemoveRequest,
    ExtensionOutboxRemoveRequestFromJSON,
    ExtensionOutboxRemoveRequestToJSON,
    ExtensionOutboxRemoveResponse,
    ExtensionOutboxRemoveResponseFromJSON,
    ExtensionOutboxRemoveResponseToJSON,
    ExtensionProfileResumeUploadResponseSerializerV2,
    ExtensionProfileResumeUploadResponseSerializerV2FromJSON,
    ExtensionProfileResumeUploadResponseSerializerV2ToJSON,
    ExtensionTrackRequest,
    ExtensionTrackRequestFromJSON,
    ExtensionTrackRequestToJSON,
    ExtensionTrackResponse,
    ExtensionTrackResponseFromJSON,
    ExtensionTrackResponseToJSON,
    ExtensionUserInfo,
    ExtensionUserInfoFromJSON,
    ExtensionUserInfoToJSON,
    InlineResponse20030,
    InlineResponse20030FromJSON,
    InlineResponse20030ToJSON,
} from '../models';

export interface ExtensionApiExtensionAddCandidateRequest {
    job: string;
    firstName: string;
    lastName: string;
    campaign: string;
    email?: string;
    emailId?: string;
    linkedinProfileUrl?: string;
    resume?: Blob;
    firstMessageBody?: string;
    firstMessageSubject?: string;
    ccEmails?: Array<string>;
}

export interface ExtensionApiExtensionApproveOutboxCandidateRequest {
    data: ExtensionOutboxApproveRequest;
}

export interface ExtensionApiExtensionCheckForExistingCandidatesRequest {
    data: ExtensionCheckForCandidatesRequest;
}

export interface ExtensionApiExtensionFindEmailsOperationRequest {
    data: ExtensionFindEmailsRequest;
}

export interface ExtensionApiExtensionGenerateOutreachOperationRequest {
    data: ExtensionGenerateOutreachRequest;
}

export interface ExtensionApiExtensionListCampaignsForJobRequest {
    id: string;
}

export interface ExtensionApiExtensionListJobsRequest {
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface ExtensionApiExtensionRemoveOutboxCandidateRequest {
    data: ExtensionOutboxRemoveRequest;
}

export interface ExtensionApiExtensionTrackActionRequest {
    data: ExtensionTrackRequest;
}

export interface ExtensionApiUploadResumeFromExtensionV2Request {
    resume: Blob;
    profileUrl: string;
    userEmail: string;
}

/**
 * ExtensionApi - interface
 * 
 * @export
 * @interface ExtensionApiInterface
 */
export interface ExtensionApiInterface {
    /**
     * add candidate
     * @param {string} job 
     * @param {string} firstName 
     * @param {string} lastName 
     * @param {string} campaign 
     * @param {string} [email] 
     * @param {string} [emailId] 
     * @param {string} [linkedinProfileUrl] 
     * @param {Blob} [resume] 
     * @param {string} [firstMessageBody] 
     * @param {string} [firstMessageSubject] 
     * @param {Array<string>} [ccEmails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionAddCandidateRaw(requestParameters: ExtensionApiExtensionAddCandidateRequest): Promise<runtime.ApiResponse<ExtensionAddCandidateResponse>>;

    /**
     * add candidate
     */
    extensionAddCandidate(requestParameters: ExtensionApiExtensionAddCandidateRequest): Promise<ExtensionAddCandidateResponse>;

    /**
     * approve outbox candidate
     * @param {ExtensionOutboxApproveRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionApproveOutboxCandidateRaw(requestParameters: ExtensionApiExtensionApproveOutboxCandidateRequest): Promise<runtime.ApiResponse<ExtensionOutboxApproveResponse>>;

    /**
     * approve outbox candidate
     */
    extensionApproveOutboxCandidate(requestParameters: ExtensionApiExtensionApproveOutboxCandidateRequest): Promise<ExtensionOutboxApproveResponse>;

    /**
     * check for existing candidates
     * @param {ExtensionCheckForCandidatesRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionCheckForExistingCandidatesRaw(requestParameters: ExtensionApiExtensionCheckForExistingCandidatesRequest): Promise<runtime.ApiResponse<ExtensionCheckForCandidatesResponse>>;

    /**
     * check for existing candidates
     */
    extensionCheckForExistingCandidates(requestParameters: ExtensionApiExtensionCheckForExistingCandidatesRequest): Promise<ExtensionCheckForCandidatesResponse>;

    /**
     * Retrieves emails for linkedin public url
     * @param {ExtensionFindEmailsRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionFindEmailsRaw(requestParameters: ExtensionApiExtensionFindEmailsOperationRequest): Promise<runtime.ApiResponse<ExtensionFindEmailsResponse>>;

    /**
     * Retrieves emails for linkedin public url
     */
    extensionFindEmails(requestParameters: ExtensionApiExtensionFindEmailsOperationRequest): Promise<ExtensionFindEmailsResponse>;

    /**
     * generate outreach
     * @param {ExtensionGenerateOutreachRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionGenerateOutreachRaw(requestParameters: ExtensionApiExtensionGenerateOutreachOperationRequest): Promise<runtime.ApiResponse<ExtensionGenerateOutreachResponse>>;

    /**
     * generate outreach
     */
    extensionGenerateOutreach(requestParameters: ExtensionApiExtensionGenerateOutreachOperationRequest): Promise<ExtensionGenerateOutreachResponse>;

    /**
     * get user info for the extension
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionGetUserInfoRaw(): Promise<runtime.ApiResponse<ExtensionUserInfo>>;

    /**
     * get user info for the extension
     */
    extensionGetUserInfo(): Promise<ExtensionUserInfo>;

    /**
     * list campaigns for job
     * @param {string} id A UUID string identifying this job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionListCampaignsForJobRaw(requestParameters: ExtensionApiExtensionListCampaignsForJobRequest): Promise<runtime.ApiResponse<ExtensionListCampaignsForJobResponse>>;

    /**
     * list campaigns for job
     */
    extensionListCampaignsForJob(requestParameters: ExtensionApiExtensionListCampaignsForJobRequest): Promise<ExtensionListCampaignsForJobResponse>;

    /**
     * list jobs
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionListJobsRaw(requestParameters: ExtensionApiExtensionListJobsRequest): Promise<runtime.ApiResponse<InlineResponse20030>>;

    /**
     * list jobs
     */
    extensionListJobs(requestParameters: ExtensionApiExtensionListJobsRequest): Promise<InlineResponse20030>;

    /**
     * remove outbox candidate
     * @param {ExtensionOutboxRemoveRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionRemoveOutboxCandidateRaw(requestParameters: ExtensionApiExtensionRemoveOutboxCandidateRequest): Promise<runtime.ApiResponse<ExtensionOutboxRemoveResponse>>;

    /**
     * remove outbox candidate
     */
    extensionRemoveOutboxCandidate(requestParameters: ExtensionApiExtensionRemoveOutboxCandidateRequest): Promise<ExtensionOutboxRemoveResponse>;

    /**
     * track an action
     * @param {ExtensionTrackRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    extensionTrackActionRaw(requestParameters: ExtensionApiExtensionTrackActionRequest): Promise<runtime.ApiResponse<ExtensionTrackResponse>>;

    /**
     * track an action
     */
    extensionTrackAction(requestParameters: ExtensionApiExtensionTrackActionRequest): Promise<ExtensionTrackResponse>;

    /**
     * handle extension resume upload returning profile details
     * @param {Blob} resume 
     * @param {string} profileUrl 
     * @param {string} userEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionApiInterface
     */
    uploadResumeFromExtensionV2Raw(requestParameters: ExtensionApiUploadResumeFromExtensionV2Request): Promise<runtime.ApiResponse<ExtensionProfileResumeUploadResponseSerializerV2>>;

    /**
     * handle extension resume upload returning profile details
     */
    uploadResumeFromExtensionV2(requestParameters: ExtensionApiUploadResumeFromExtensionV2Request): Promise<ExtensionProfileResumeUploadResponseSerializerV2>;

}

/**
 * 
 */
export class ExtensionApi extends runtime.BaseAPI implements ExtensionApiInterface {

    /**
     * add candidate
     */
    async extensionAddCandidateRaw(requestParameters: ExtensionApiExtensionAddCandidateRequest): Promise<runtime.ApiResponse<ExtensionAddCandidateResponse>> {
        if (requestParameters.job === null || requestParameters.job === undefined) {
            throw new runtime.RequiredError('job','Required parameter requestParameters.job was null or undefined when calling extensionAddCandidate.');
        }

        if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
            throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling extensionAddCandidate.');
        }

        if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
            throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling extensionAddCandidate.');
        }

        if (requestParameters.campaign === null || requestParameters.campaign === undefined) {
            throw new runtime.RequiredError('campaign','Required parameter requestParameters.campaign was null or undefined when calling extensionAddCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.job !== undefined) {
            formParams.append('job', requestParameters.job as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.emailId !== undefined) {
            formParams.append('email_id', requestParameters.emailId as any);
        }

        if (requestParameters.linkedinProfileUrl !== undefined) {
            formParams.append('linkedin_profile_url', requestParameters.linkedinProfileUrl as any);
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        if (requestParameters.campaign !== undefined) {
            formParams.append('campaign', requestParameters.campaign as any);
        }

        if (requestParameters.firstMessageBody !== undefined) {
            formParams.append('first_message_body', requestParameters.firstMessageBody as any);
        }

        if (requestParameters.firstMessageSubject !== undefined) {
            formParams.append('first_message_subject', requestParameters.firstMessageSubject as any);
        }

        if (requestParameters.ccEmails) {
            formParams.append('cc_emails', requestParameters.ccEmails.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        const response = await this.request({
            path: `/api/v1/extension/add-candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionAddCandidateResponseFromJSON(jsonValue));
    }

    /**
     * add candidate
     */
    async extensionAddCandidate(requestParameters: ExtensionApiExtensionAddCandidateRequest): Promise<ExtensionAddCandidateResponse> {
        const response = await this.extensionAddCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * approve outbox candidate
     */
    async extensionApproveOutboxCandidateRaw(requestParameters: ExtensionApiExtensionApproveOutboxCandidateRequest): Promise<runtime.ApiResponse<ExtensionOutboxApproveResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionApproveOutboxCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/approve-outbox-candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionOutboxApproveRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionOutboxApproveResponseFromJSON(jsonValue));
    }

    /**
     * approve outbox candidate
     */
    async extensionApproveOutboxCandidate(requestParameters: ExtensionApiExtensionApproveOutboxCandidateRequest): Promise<ExtensionOutboxApproveResponse> {
        const response = await this.extensionApproveOutboxCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * check for existing candidates
     */
    async extensionCheckForExistingCandidatesRaw(requestParameters: ExtensionApiExtensionCheckForExistingCandidatesRequest): Promise<runtime.ApiResponse<ExtensionCheckForCandidatesResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionCheckForExistingCandidates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/check-for-existing-candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionCheckForCandidatesRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionCheckForCandidatesResponseFromJSON(jsonValue));
    }

    /**
     * check for existing candidates
     */
    async extensionCheckForExistingCandidates(requestParameters: ExtensionApiExtensionCheckForExistingCandidatesRequest): Promise<ExtensionCheckForCandidatesResponse> {
        const response = await this.extensionCheckForExistingCandidatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves emails for linkedin public url
     */
    async extensionFindEmailsRaw(requestParameters: ExtensionApiExtensionFindEmailsOperationRequest): Promise<runtime.ApiResponse<ExtensionFindEmailsResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionFindEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/find-emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionFindEmailsRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionFindEmailsResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves emails for linkedin public url
     */
    async extensionFindEmails(requestParameters: ExtensionApiExtensionFindEmailsOperationRequest): Promise<ExtensionFindEmailsResponse> {
        const response = await this.extensionFindEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * generate outreach
     */
    async extensionGenerateOutreachRaw(requestParameters: ExtensionApiExtensionGenerateOutreachOperationRequest): Promise<runtime.ApiResponse<ExtensionGenerateOutreachResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionGenerateOutreach.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/generate-outreach`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionGenerateOutreachRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionGenerateOutreachResponseFromJSON(jsonValue));
    }

    /**
     * generate outreach
     */
    async extensionGenerateOutreach(requestParameters: ExtensionApiExtensionGenerateOutreachOperationRequest): Promise<ExtensionGenerateOutreachResponse> {
        const response = await this.extensionGenerateOutreachRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user info for the extension
     */
    async extensionGetUserInfoRaw(): Promise<runtime.ApiResponse<ExtensionUserInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/userinfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionUserInfoFromJSON(jsonValue));
    }

    /**
     * get user info for the extension
     */
    async extensionGetUserInfo(): Promise<ExtensionUserInfo> {
        const response = await this.extensionGetUserInfoRaw();
        return await response.value();
    }

    /**
     * list campaigns for job
     */
    async extensionListCampaignsForJobRaw(requestParameters: ExtensionApiExtensionListCampaignsForJobRequest): Promise<runtime.ApiResponse<ExtensionListCampaignsForJobResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling extensionListCampaignsForJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/jobs/{id}/list_campaigns_for_job`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionListCampaignsForJobResponseFromJSON(jsonValue));
    }

    /**
     * list campaigns for job
     */
    async extensionListCampaignsForJob(requestParameters: ExtensionApiExtensionListCampaignsForJobRequest): Promise<ExtensionListCampaignsForJobResponse> {
        const response = await this.extensionListCampaignsForJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * list jobs
     */
    async extensionListJobsRaw(requestParameters: ExtensionApiExtensionListJobsRequest): Promise<runtime.ApiResponse<InlineResponse20030>> {
        const queryParameters: any = {};

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20030FromJSON(jsonValue));
    }

    /**
     * list jobs
     */
    async extensionListJobs(requestParameters: ExtensionApiExtensionListJobsRequest): Promise<InlineResponse20030> {
        const response = await this.extensionListJobsRaw(requestParameters);
        return await response.value();
    }

    /**
     * remove outbox candidate
     */
    async extensionRemoveOutboxCandidateRaw(requestParameters: ExtensionApiExtensionRemoveOutboxCandidateRequest): Promise<runtime.ApiResponse<ExtensionOutboxRemoveResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionRemoveOutboxCandidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/remove-outbox-candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionOutboxRemoveRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionOutboxRemoveResponseFromJSON(jsonValue));
    }

    /**
     * remove outbox candidate
     */
    async extensionRemoveOutboxCandidate(requestParameters: ExtensionApiExtensionRemoveOutboxCandidateRequest): Promise<ExtensionOutboxRemoveResponse> {
        const response = await this.extensionRemoveOutboxCandidateRaw(requestParameters);
        return await response.value();
    }

    /**
     * track an action
     */
    async extensionTrackActionRaw(requestParameters: ExtensionApiExtensionTrackActionRequest): Promise<runtime.ApiResponse<ExtensionTrackResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling extensionTrackAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/extension/analytics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtensionTrackRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionTrackResponseFromJSON(jsonValue));
    }

    /**
     * track an action
     */
    async extensionTrackAction(requestParameters: ExtensionApiExtensionTrackActionRequest): Promise<ExtensionTrackResponse> {
        const response = await this.extensionTrackActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * handle extension resume upload returning profile details
     */
    async uploadResumeFromExtensionV2Raw(requestParameters: ExtensionApiUploadResumeFromExtensionV2Request): Promise<runtime.ApiResponse<ExtensionProfileResumeUploadResponseSerializerV2>> {
        if (requestParameters.resume === null || requestParameters.resume === undefined) {
            throw new runtime.RequiredError('resume','Required parameter requestParameters.resume was null or undefined when calling uploadResumeFromExtensionV2.');
        }

        if (requestParameters.profileUrl === null || requestParameters.profileUrl === undefined) {
            throw new runtime.RequiredError('profileUrl','Required parameter requestParameters.profileUrl was null or undefined when calling uploadResumeFromExtensionV2.');
        }

        if (requestParameters.userEmail === null || requestParameters.userEmail === undefined) {
            throw new runtime.RequiredError('userEmail','Required parameter requestParameters.userEmail was null or undefined when calling uploadResumeFromExtensionV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.resume !== undefined) {
            formParams.append('resume', requestParameters.resume as any);
        }

        if (requestParameters.profileUrl !== undefined) {
            formParams.append('profile_url', requestParameters.profileUrl as any);
        }

        if (requestParameters.userEmail !== undefined) {
            formParams.append('user_email', requestParameters.userEmail as any);
        }

        const response = await this.request({
            path: `/api/v1/extension/parse-resume`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtensionProfileResumeUploadResponseSerializerV2FromJSON(jsonValue));
    }

    /**
     * handle extension resume upload returning profile details
     */
    async uploadResumeFromExtensionV2(requestParameters: ExtensionApiUploadResumeFromExtensionV2Request): Promise<ExtensionProfileResumeUploadResponseSerializerV2> {
        const response = await this.uploadResumeFromExtensionV2Raw(requestParameters);
        return await response.value();
    }

}
