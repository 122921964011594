import { skipToken } from "@reduxjs/toolkit/dist/query";
import { sortBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { SortingRule } from "react-table";
import { useDeepCompareEffect } from "react-use";
import { useQueryParams, DecodedValueMap, SetQuery } from "use-query-params";

import { useCandidateId } from "hooks/useCandidateId";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl, { useJobId } from "hooks/useJobIdFromUrl";
import { useListSaapReviewApplicationsQuery } from "services/doverapi/endpoints/applicationReview";
import { useGetCandidateBioQuery, useGetCandidatesNextActionsQuery } from "services/doverapi/endpoints/candidate";
import {
  useGetCandidateCountsQuery,
  useGetCandidateV2Query,
  useListPipelineCandidatesQuery,
} from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { selectFromListJobsQueryResult, useListJobsQuery } from "services/doverapi/endpoints/job";
import { selectJobById } from "services/doverapi/endpoints/job/endpoints";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import {
  ApiApiListPipelineCandidatesRequest,
  CandidateFilter,
  CandidateFilterList,
  CandidateFilterNeedsActionEnum,
  CandidateFilterPendingCustomerResponseEnum,
  CandidateFilterSourcingContextEnum,
  CandidateFilterStatusEnum,
  CandidateNextAction,
  ClientPaymentMethodCollectionExperimentEnum,
  DashboardJob,
  HiringPipelineStage,
  HiringPipelineStageMilestone,
  HiringPipelineStageType,
  InterviewSubstageEnum,
  JobFeatureStateEnum,
  ListSaapReviewApplicationRequestOrderingEnum,
  PipelineCandidate,
  SaapReviewAdditionalFiltersCriteriaMatchEnum,
  SearchV3SearchTypeEnum,
} from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { argsOrSkip } from "utils/argsOrSkip";
import {
  isContactedStage,
  isQueuedStage,
  needsAction,
  isRespondedStage,
  isActiveInterviewPipelineStage,
  isAppReviewStage,
} from "utils/isStage";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";
import { getNextActionLabel, filterParamConfig, CANDIDATES_LIMIT } from "views/candidates/constants";
import {
  CandidateNextActionMap,
  EnrichedNextAction,
  PipelineExpandOption,
  QuickFilterEnum,
} from "views/candidates/types";
import { useRealHps, useStageIdsByType } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

export interface QuickFilterParam {
  name: QuickFilterEnum;
  filters: {
    hpsId?: string[];
    status?: CandidateFilterStatusEnum[];
    substages?: InterviewSubstageEnum[];
    needsAction?: CandidateFilterNeedsActionEnum[];
    pendingCustomerResponse?: CandidateFilterPendingCustomerResponseEnum;
    hasMention?: boolean;
  };
}

export const useDefaultQueryParams = (): Partial<DecodedValueMap<typeof filterParamConfig>> => {
  const { stages } = useRealHps();
  const initialCallStage = stages?.find(stage => stage.milestone === HiringPipelineStageMilestone.INITIAL_CALL);

  return {
    hpsId: initialCallStage ? [initialCallStage.id] : [],
    status: [CandidateFilterStatusEnum.Active],
    quickFilter: undefined,
  };
};

// --------------------------------
// URL Param Hooks
// --------------------------------

/**
 * Provides easy access to query param values and a setter function
 */
export const useParams = (): [
  DecodedValueMap<typeof filterParamConfig> & { jobId: string | undefined },
  SetQuery<typeof filterParamConfig>
] => {
  const [params, setParams] = useQueryParams(filterParamConfig);
  const jobId = useJobIdFromUrl();

  return useMemo(() => [{ ...params, jobId }, setParams], [jobId, params, setParams]);
};

/**
 * This hook sets the query params back to default
 */
export const useResetParams = (): (() => void) => {
  const [, setParams] = useParams();

  const defaultQueryParams = useDefaultQueryParams();

  return (): void => setParams(defaultQueryParams);
};

export const useIsQuickFilterActive = (qf: QuickFilterEnum): boolean => {
  const [{ quickFilter }] = useParams();

  return useMemo(() => quickFilter === qf, [quickFilter, qf]);
};

/**
 * A hook that returns the currently selected job based on the job query param
 */
export function useJob(jobIdProp?: string): DashboardJob | undefined {
  const [{ jobId: jobIdParam }] = useParams();
  const jobId = jobIdProp ?? jobIdParam;

  const { data: jobs } = useListJobsQuery(undefined);

  return jobId && jobs ? selectJobById(jobs, jobId) : undefined;
}

export const useCandidateReviewStages = (jobId?: string): HiringPipelineStage[] | undefined => {
  const stagesResult = useRealHps(jobId);

  return stagesResult.stages?.filter(needsAction);
};

export const useBoardStages = (jobId?: string): HiringPipelineStage[] | undefined => {
  const isMergeAppReviewEnabled = useFeatureFlag(FeatureFlag.MergeAppReview);

  const stagesResult = useRealHps(jobId);

  return stagesResult.stages?.filter(
    stage => needsAction(stage) || (isMergeAppReviewEnabled && isAppReviewStage(stage))
  );
};

export const useBoardStagesV2 = (jobId?: string): HiringPipelineStage[] | undefined => {
  const stagesResult = useRealHps(jobId);

  return stagesResult.stages;
};

/**
 * This hook filters out stage 400 from the list of stages because we include that in the Responded tab instead of any of the Interviewing tabs
 */
export const useInterviewingStages = (jobId?: string): HiringPipelineStage[] | undefined => {
  const stagesResult = useRealHps(jobId);

  return stagesResult.stages?.filter(isActiveInterviewPipelineStage);
};

const useExpand = (): string | undefined => {
  const [{ sourcingContext }] = useParams();

  return sourcingContext.includes(CandidateFilterSourcingContextEnum.Outbound) ||
    sourcingContext.includes(CandidateFilterSourcingContextEnum.ManuallyAdded) ||
    !sourcingContext.length
    ? PipelineExpandOption.CampaignMessageRequest
    : undefined;
};

// --------------------------------
// RTKQ Hooks
// --------------------------------

export const convertQueryQuickFilterToApi = ({ filters }: QuickFilterParam): CandidateFilter => {
  return {
    pipelineStages: filters.hpsId?.map(hpsId => ({
      pipelineStageId: hpsId,
      pipelineSubstages: filters.substages ?? [],
    })),
    status: filters.status,
    needsAction: filters.needsAction,
    pendingCustomerResponse: filters.pendingCustomerResponse,
    hasMention: filters.hasMention ?? null,
  };
};

/**
 * A hook to return the arguments for useListPipelineCandidates based on the current filtering
 **/
export const useGetArgs = (
  limitOverride: number = CANDIDATES_LIMIT,
  stageIdsOverride?: string[],
  jobIdOverride?: string,
  provideCandidateBucketLabels?: boolean
): ApiApiListPipelineCandidatesRequest => {
  const [{ jobId: jobIdParam, quickFilter, page, hpsId, substages, ordering, status, sourcingContext }] = useParams();
  const expand = useExpand();
  const jobId = jobIdOverride ?? jobIdParam;
  const quickFilters = useGetQuickFilters();

  const jobHasScoringEnabled = useJobHasScoringEnabled();

  const filters: CandidateFilter = useMemo(() => {
    // If quick filter is set use that to filter the api
    if (quickFilter) {
      return convertQueryQuickFilterToApi(quickFilters[quickFilter]);
    }

    // Otherwise use the other filters set in query params
    const stagesToShow = stageIdsOverride ?? hpsId;

    return {
      pipelineStages: stagesToShow
        ?.filter(stageId => Boolean(stageId))
        .map(stageId => {
          return {
            pipelineStageId: stageId!,
            pipelineSubstages: substages?.filter(s => Boolean(s)).map(s => parseInt(s!)) ?? [],
          };
        }),
      status,
      sourcingContext,
    };
  }, [quickFilter, stageIdsOverride, hpsId, status, sourcingContext, quickFilters, substages]);

  const args = useMemo(() => {
    return {
      jobId: jobId!, // skipToken will be provided if job id is undefined
      data: { filters, provideCandidateBucketLabels: jobHasScoringEnabled && provideCandidateBucketLabels },
      expand,
      ordering,
      limit: limitOverride,
      offset: page * limitOverride,
    };
  }, [expand, filters, jobHasScoringEnabled, jobId, limitOverride, ordering, page, provideCandidateBucketLabels]);

  return args;
};

// The type definition is huge and I don't know how to manually recreate it
// The implied type is correct
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCandidates = ({
  limitOverride = CANDIDATES_LIMIT,
  stageIdsOverride,
  jobIdOverride,
  provideCandidateBucketLabels,
}: {
  limitOverride?: number;
  stageIdsOverride?: string[];
  jobIdOverride?: string;
  provideCandidateBucketLabels?: boolean;
} = {}) => {
  const [{ jobId: jobIdParam }] = useParams();
  const jobId = jobIdOverride ?? jobIdParam;

  const args = useGetArgs(limitOverride, stageIdsOverride, jobIdOverride, provideCandidateBucketLabels);
  const shouldSkip = !jobId;
  const useSuperApi = true;
  const results = useListPipelineCandidatesQuery(argsOrSkip({ args, useSuperApi }, shouldSkip));

  return results;
};

// Take in a list of quick filter names and list of quick filters
// Combine them into a single list into the format the count api expexts
export const convertQuickFiltersToCountList = (
  names: Array<QuickFilterEnum>,
  filters: Record<QuickFilterEnum, QuickFilterParam>
): Array<CandidateFilterList> => names.map(name => ({ name, filters: convertQueryQuickFilterToApi(filters[name]) }));

// The type definition is huge and I don't know how to manually recreate it
// The implied type is correct
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCounts = (jobIdProp?: string): { isFetching: boolean; counts: Record<string, number> | undefined } => {
  const [{ jobId: jobIdParam }] = useParams();
  const jobId = jobIdProp ?? jobIdParam;
  const { stages } = useRealHps();
  const [{ status, sourcingContext }] = useParams();
  const quickFilters = useGetQuickFilters();

  /*
    ---------------------------------
    Get the filters for each stage tab
    ---------------------------------
  */
  const stageFilterBuilder = (stageType: HiringPipelineStageType): CandidateFilterList[] => {
    return (
      stages
        ?.filter(s => s.stageType === stageType)
        .map(
          (s): CandidateFilterList => ({
            name: s.name!,
            filters: {
              pipelineStages: [{ pipelineStageId: s.id, pipelineSubstages: [] }],
              status,

              sourcingContext,
            },
          })
        ) ?? []
    );
  };

  const appReviewStageFilters = stageFilterBuilder(HiringPipelineStageType.APPLICATION_REVIEW);
  const queuedStageFilters = stageFilterBuilder(HiringPipelineStageType.QUEUED);
  const contactedStageFilters = stageFilterBuilder(HiringPipelineStageType.CONTACTED);
  const respondedStageFilters = stageFilterBuilder(HiringPipelineStageType.RESPONDED);
  const appliedStageFilters = stageFilterBuilder(HiringPipelineStageType.APPLICATION_REVIEW);
  const interviewingStageFilters = stageFilterBuilder(HiringPipelineStageType.INTERVIEW);
  const offerStageFilters = stageFilterBuilder(HiringPipelineStageType.OFFER);

  /*
    ---------------------------------
    Get the filters for each quick filter
    ---------------------------------
  */
  const quickFilterLists = convertQuickFiltersToCountList(
    [QuickFilterEnum.Rejected, QuickFilterEnum.Interviewing, QuickFilterEnum.Scheduled],
    quickFilters
  );

  const countFilters = [
    ...queuedStageFilters,
    ...contactedStageFilters,
    ...appReviewStageFilters,
    ...respondedStageFilters,
    ...appliedStageFilters,
    ...quickFilterLists,
    ...offerStageFilters,
  ];

  interviewingStageFilters && countFilters.push(...interviewingStageFilters);

  const args = {
    jobId: jobId!, // skipToken will be provided if job id is undefined
    data: {
      countFilters,
    },
  };

  const shouldSkip = !jobId || !stages || !stages.length;

  const useSuperApi = true;

  const { data, isFetching } = useGetCandidateCountsQuery(argsOrSkip({ args, useSuperApi }, shouldSkip));

  return {
    isFetching,
    counts: data?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: curr.count,
      }),
      {}
    ),
  };
};

export const useCountByStageName = (stageName: string): number | undefined => {
  const { counts } = useCounts();
  return counts?.[stageName];
};

interface UseCountBestMatchApplicantsReturn {
  count: number | undefined;
  isFetching: boolean;
}

export const useCountApplicantsByBucketLabel = ({
  bucketLabel,
  jobId,
  skip = false,
}: {
  bucketLabel: SaapReviewAdditionalFiltersCriteriaMatchEnum | undefined;
  jobId?: string;
  // pass in skip to not actually make this request
  skip?: boolean;
}): UseCountBestMatchApplicantsReturn => {
  const { currentData: inboundSearches, isFetching: isInboundSearchesLoading } = useListSearchesV3Query(
    jobId ? { job: jobId, searchTypeList: SearchV3SearchTypeEnum.Inbound } : skipToken
  );

  const search = React.useMemo(() => {
    if (!inboundSearches) {
      return undefined;
    }
    const activeInboundSearches = inboundSearches.filter(search => search.active);
    return activeInboundSearches?.length ? activeInboundSearches[0] : inboundSearches[0];
  }, [inboundSearches]);

  const searchId = search?.id;

  const args = {
    searchId: searchId || "", // default to empty string to satisfy the typing but only call API if it exists
    additionalFilters: {
      criteriaMatch: bucketLabel ? [bucketLabel] : undefined,
    },
    page: 0,
    // we don't actually need to pull any data back here so limit to 0
    limit: 0,
    ordering: ListSaapReviewApplicationRequestOrderingEnum.Best,
  };

  const { currentData: formResults, isFetching: isFetchingApplications } = useListSaapReviewApplicationsQuery(
    searchId && !skip ? args : skipToken
  );

  return { count: formResults?.totalCount, isFetching: isFetchingApplications || isInboundSearchesLoading };
};

interface NextActionResponseData {
  data?: CandidateNextActionMap;
  isFetching?: boolean;
  isUninitialized?: boolean;
}

export function useListCandidatesWithNextActions(candidateIds: string[] | undefined): NextActionResponseData {
  const { data: candidateNextActions, isFetching, isUninitialized } = useGetCandidatesNextActionsQuery(
    candidateIds || skipToken
  );

  const candidatesNextActionsMap = useMemo(() => {
    const map: CandidateNextActionMap = {};
    candidateNextActions?.forEach((candidateNextAction: CandidateNextAction) => {
      const { id, nextAction, schedulingOwnership } = candidateNextAction;
      const nextActionLabel = getNextActionLabel(candidateNextAction.nextAction) || "";
      const enrichedNextAction: EnrichedNextAction | undefined = nextAction
        ? { ...nextAction, label: nextActionLabel, schedulingOwnership }
        : undefined;
      map[id!] = enrichedNextAction!;
    });
    return map;
  }, [candidateNextActions]);

  return {
    data: candidatesNextActionsMap,
    isFetching,
    isUninitialized,
  };
}

export function useGetDisableCandidateActions(): boolean {
  const [{ jobId }] = useParams();

  const managedOutboundState = useGetManagedOutboundState({ jobId });

  return managedOutboundState === JobFeatureStateEnum.Disabled;
}

// --------------------------------
// Effects
// --------------------------------
/**
 * We need to reset the page to 0 when any filters change
 */
export const useResetPage = (): void => {
  const [{ ordering, status, sourcingContext }, setParams] = useParams();

  useDeepCompareEffect(() => {
    setParams({ page: 0 });
  }, [setParams, ordering, status, sourcingContext]);
};

// --------------------------------
// Helpers / Derived data
// --------------------------------
/**
 * Checks that the stages passed in and only the stages passed in are selected
 */
export const useAreStagesActive = (stageIds: string[]): boolean => {
  const [{ hpsId }] = useParams();

  return hpsId?.length === stageIds.length && stageIds.every(s => hpsId.includes(s));
};

/**
 * Checks if the contcted stage is selected.
 * This is a little more complicated than the other stages because there is a special
 * dropdown filter on the contacted stage that lets you choose a specific contacted stage.
 * So, this checks if exactly one of the contacted stages is selected or if all of them are.
 */
export const useIsContactedStageSelected = (): boolean => {
  const contactedStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.CONTACTED });
  return useAreStagesActive(contactedStageIds);
};

export const useIsApplicantsActive = (): boolean => {
  const contactedStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.APPLICATION_REVIEW });
  return useAreStagesActive(contactedStageIds);
};

export const useIsLeadsActive = (): boolean => {
  const stagesResult = useRealHps();
  const queuedStageIds = stagesResult.stages?.filter(stage => isQueuedStage(stage)).map(s => s.id) || [];
  const contactedStageIds = stagesResult.stages?.filter(stage => isContactedStage(stage)).map(s => s.id) || [];
  const responseStageIds = stagesResult.stages?.filter(stage => isRespondedStage(stage)).map(s => s.id) || [];
  const isQueuedActive = useAreStagesActive(queuedStageIds);
  const isContactedActive = useAreStagesActive(contactedStageIds);
  const isRespondedActive = useAreStagesActive(responseStageIds);

  return isQueuedActive || isContactedActive || isRespondedActive;
};

export const useIsInterviewingActive = (): boolean => {
  const interviewingStages = useInterviewingStages();
  const [{ hpsId }] = useParams();

  return hpsId?.length === 1 && !!interviewingStages?.some(s => s.id === hpsId[0]);
};

/**
 * Turns the sortby object returned by react table into an ordering string django can use for sorting
 */
export const getOrderingString = (sortBy: SortingRule<PipelineCandidate>[]): string | undefined => {
  if (!sortBy.length) {
    return undefined;
  }

  return sortBy.reduce((acc: string, curr) => {
    const commaOrEmpty = acc ? "," : "";
    const prefix = curr.desc ? "" : "-";
    return acc + commaOrEmpty + prefix + curr.id;
  }, "");
};

/**
 * This hook returns the actual filter objects that fit what the API expects for each quick filter
 */
export const useGetQuickFilters = (): Record<QuickFilterEnum, QuickFilterParam> => {
  const { stages } = useRealHps();

  const appReviewStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.APPLICATION_REVIEW });
  const respondedStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.RESPONDED });
  const interviewStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.INTERVIEW });

  const allNeedsActionHpsIds = stages?.filter(stage => needsAction(stage)).map(s => s.id);

  return (
    // Review page quick filters
    {
      [QuickFilterEnum.Applicants]: {
        name: QuickFilterEnum.Applicants,
        filters: {
          hpsId: appReviewStageIds,
          status: [CandidateFilterStatusEnum.Active],
        },
      },
      [QuickFilterEnum.AllNeedsAction]: {
        name: QuickFilterEnum.AllNeedsAction,
        filters: {
          hpsId: allNeedsActionHpsIds,
          status: [CandidateFilterStatusEnum.Active],

          needsAction: [CandidateFilterNeedsActionEnum.NeedsAction],
        },
      },
      [QuickFilterEnum.RespondedAsInterested]: {
        name: QuickFilterEnum.RespondedAsInterested,
        filters: {
          hpsId: respondedStageIds,
          status: [CandidateFilterStatusEnum.Active],

          pendingCustomerResponse: CandidateFilterPendingCustomerResponseEnum.No,
        },
      },
      [QuickFilterEnum.PendingCustomerResponse]: {
        name: QuickFilterEnum.PendingCustomerResponse,
        filters: {
          hpsId: respondedStageIds,
          status: [CandidateFilterStatusEnum.Active],

          pendingCustomerResponse: CandidateFilterPendingCustomerResponseEnum.Yes,
        },
      },
      [QuickFilterEnum.CompletedInterview]: {
        name: QuickFilterEnum.CompletedInterview,
        filters: {
          hpsId: interviewStageIds,
          substages: [InterviewSubstageEnum.COMPLETED],
          status: [CandidateFilterStatusEnum.Active],
        },
      },
      [QuickFilterEnum.Mentions]: {
        name: QuickFilterEnum.Mentions,
        filters: {
          hasMention: true,
        },
      },

      // Pipeline view quick filters
      [QuickFilterEnum.Interviewing]: {
        name: QuickFilterEnum.Interviewing,
        filters: {
          hpsId: interviewStageIds,
          substages: [],
          status: [CandidateFilterStatusEnum.Active],
        },
      },
      [QuickFilterEnum.Scheduled]: {
        name: QuickFilterEnum.Scheduled,
        filters: {
          hpsId: interviewStageIds,
          substages: [InterviewSubstageEnum.SCHEDULED],
          status: [CandidateFilterStatusEnum.Active],
        },
      },
      [QuickFilterEnum.Rejected]: {
        name: QuickFilterEnum.Rejected,
        filters: {
          status: [CandidateFilterStatusEnum.Rejected],
        },
      },
    }
  );
};

export const useIsSampleCandidate = (candidateId: string | undefined): boolean => {
  const { data: candidate } = useGetCandidateV2Query(
    candidateId
      ? {
          id: candidateId,
          expand: PipelineExpandOption.CampaignMessageRequest,
        }
      : skipToken
  );

  // I hate everything about how we handle sample candidates.
  // I'm pretty sure we can just kill that entire flow, since you can only create a sample Max through an obscure flow.
  return React.useMemo<boolean>(() => {
    return !!candidate && !!(candidate.contact?.fullName == "Max Kolysh");
  }, [candidate]);
};

export const useShouldGateBasePlanOnCreditCard = (candidateId: string | undefined): boolean => {
  // Paywall - block user from queueing campaign message request if they have no default payment method
  const { data: client } = useGetUsersClientQuery();
  const isBasePlan = useIsBasePlanCustomer();
  const isSampleCandidate = useIsSampleCandidate(candidateId);

  return React.useMemo<boolean>(() => {
    return (
      !!client &&
      isBasePlan &&
      client.paymentMethodCollectionExperiment ===
        ClientPaymentMethodCollectionExperimentEnum.AfterEnablingAutopilotOrSending &&
      !client?.hasDefaultPaymentMethod &&
      !isSampleCandidate
    );
  }, [client, isBasePlan, isSampleCandidate]);
};

export const useHasManuallySourcedCandidates = (
  jobId: string | undefined,
  skip: boolean = false
): { hasManuallySourcedCandidates: boolean; isFetching: boolean } => {
  const manuallySourcedFilter: CandidateFilterList = {
    name: "manuallySourced",
    filters: {
      status: [],

      sourcingContext: [CandidateFilterSourcingContextEnum.ManuallyAdded],
    },
  };

  const { data, isFetching } = useGetCandidateCountsQuery(
    argsOrSkip(
      {
        args: {
          jobId: jobId!,
          data: {
            countFilters: [manuallySourcedFilter],
          },
        },
        useSuperApi: true,
      },
      !jobId || skip
    )
  );

  const manuallySourcedCount = data?.[0]?.count;

  return React.useMemo(() => {
    return { hasManuallySourcedCandidates: !!manuallySourcedCount, isFetching };
  }, [isFetching, manuallySourcedCount]);
};

interface UseJobOrDefaultReturn {
  job: DashboardJob | undefined;
  isLoading: boolean;
}

const sortFunc = (jobs: DashboardJob[]): DashboardJob[] => {
  return sortBy(jobs, ["title"], ["asc"]);
};

// Get the job from jobid in the url
// If there is not jobid in the url choose a sensible default
export const useJobOrDefault = (): UseJobOrDefaultReturn => {
  // Get job id and candidate id from url
  const [jobId, setJobId] = useJobId();
  const candidateId = useCandidateId();

  // Get auth info
  const {
    user: { email: authedUserEmail },
  } = useAuth0();

  // Fetch the jobs
  const { data: jobs, firstJob, isLoading: isJobsLoading, isUninitialized: isJobsUninitialized } = useListJobsQuery(
    undefined,
    {
      selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults, { authedUserEmail, sortFunc }),
    }
  );

  // Fetch the candidateBio if a candidateId exists
  const {
    data: candidateBio,
    isLoading: isCandidateBioLoading,
    isUninitialized: isCandidateBioUninitialized,
  } = useGetCandidateBioQuery(candidateId ?? skipToken);

  // We only care about the candidate bio route's loading state if a candidate id is included in url
  const loadingCandidateBio = Boolean(candidateId) && (isCandidateBioLoading || isCandidateBioUninitialized);

  // Set the default job if one isn't set
  useEffect(() => {
    // If job id is already set we don't need to do anything
    if (jobId) {
      return;
    }

    // Wait until the data we need is done loading in
    if (isJobsLoading || isJobsUninitialized || loadingCandidateBio) {
      return;
    }

    // If we have a candidate set we should prioritize the job for that candidate
    // This means we are opening the candidate detail modal
    if (candidateBio) {
      setJobId(candidateBio.job);
    } else if (firstJob) {
      setJobId(firstJob.id);
    }
  }, [
    candidateBio,
    candidateId,
    firstJob,
    isCandidateBioLoading,
    isCandidateBioUninitialized,
    isJobsLoading,
    isJobsUninitialized,
    jobId,
    loadingCandidateBio,
    setJobId,
  ]);

  return {
    job: jobId && jobs ? selectJobById(jobs, jobId) : undefined,
    isLoading: isJobsLoading || isJobsUninitialized || loadingCandidateBio,
  };
};
