import { useHasFlag } from "components/RBAC";

export enum FeatureFlag {
  AISuggestedQuestions = "use_ai_suggested_interview_questions",
  DisableTitlesAutocomplete = "disable_titles_autocomplete",
  AINotetaker = "use_ai_notetaker",
  HubspotDoverEE = "hubspot_dover_ee",
  ExportCandidateCSV = "export_candidate_csv",
  AINotetakerUIConfig = "ai_notetaker_ui_config",
  DoverTalentNetwork = "dover_talent_network",
  RipSplashPages = "use_rip_splash_pages",
  SimpReview = "conditional_app_review_scoring",
  MergeAppReview = "merge_app_review",
  ShowNoReplyEmail = "show_no_reply_email",
  SimplifiedAddCandidate = "simplified_add_candidate",
  SourcingPage = "sourcing_page",
  SlugPoweredCareersPageUrls = "slug_powered_careers_page_urls",
  LegacySourcingAutopilot = "legacy_sourcing_autopilot",
  AdvertiseSourcingExtension = "advertise_sourcing_extension",
  Hotkeys = "hotkeys",
}

export function useFeatureFlag(featureFlag: FeatureFlag): boolean {
  return useHasFlag(featureFlag);
}
