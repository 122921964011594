import { Box, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Subtitle2 } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useJobId } from "hooks/useJobIdFromUrl";
import {
  selectFromListCampaignsQueryResult,
  useGenerateAndSaveCampaignContentMutation,
  useListCampaignsQuery,
} from "services/doverapi/endpoints/campaign";
import { useGetCompanySetupStepsQuery } from "services/doverapi/endpoints/client/endpoints";
import { getCompanySetupStepMap } from "services/doverapi/endpoints/company-setup/utils";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { CompanySetupStepStateEnum, CompanySetupStepStepNameEnum } from "services/openapi";
import CampaignCardV2 from "views/job/JobSetup/steps/CampaignVariants/CampaignCardV2";
import { CampaignDrawerAtom } from "views/job/JobSetup/steps/CampaignVariants/CampaignDrawer";
import { CompanyPitchIncompleteBanner } from "views/job/JobSetup/steps/CampaignVariants/components/CompanyPitchIncompleteBanner";
import { JobPitchIncompleteBanner } from "views/job/JobSetup/steps/CampaignVariants/components/JobPitchIncompleteBanner";
import {
  useShouldAttemptCampaignOutreachGeneration,
  useShouldShowIncompleteJobPitchModal,
} from "views/job/JobSetup/steps/CampaignVariants/hooks";

const EmailCampaigns = (): React.ReactElement => {
  const { open: openCampaignDrawer } = useModal(CampaignDrawerAtom);

  const [jobId] = useJobId();
  const { data: jobSetup, isFetching: isFetchingJobSetup } = useGetJobSetupQuery(jobId ?? skipToken);

  const { allCampaigns: campaigns, isSuccess, isFetching: isFetchingCampaigns } = useListCampaignsQuery(
    jobId ? { jobId } : skipToken,
    {
      selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
    }
  );

  const [dismissedJobPitchModal, setDismissedJobPitchModal] = useState<boolean>(false);
  const shouldAttemptCampaignOutreachGeneration = useShouldAttemptCampaignOutreachGeneration(
    jobId,
    dismissedJobPitchModal
  );
  const [
    generateAndSaveCampaignContent,
    { isLoading: isGeneratingCampaignContent },
  ] = useGenerateAndSaveCampaignContentMutation();
  const shouldShowIncompleteJobPitchModal = useShouldShowIncompleteJobPitchModal(
    jobId,
    dismissedJobPitchModal,
    campaigns,
    isGeneratingCampaignContent
  );
  const { data: companySetupSteps } = useGetCompanySetupStepsQuery();
  const companyStepNameToInfoMap = companySetupSteps ? getCompanySetupStepMap(companySetupSteps) : undefined;

  /* Local state */
  const [hasAttemptedToGenerateAndSaveCampaignContent, setHasAttemptedToGenerateAndSaveCampaignContent] = useState<
    boolean
  >(false);

  const shouldShowIncompleteCompanyPitchModal = React.useMemo<boolean | undefined>(() => {
    if (companyStepNameToInfoMap) {
      return [CompanySetupStepStateEnum.Pending, CompanySetupStepStateEnum.StartedPendingClientAction].includes(
        companyStepNameToInfoMap[CompanySetupStepStepNameEnum.PitchState].state
      );
    }
    return undefined;
  }, [companyStepNameToInfoMap]);

  /* Conditionally generate first outreach */
  useEffect(() => {
    async function generateAndSaveCampaignContentAPI(): Promise<void> {
      await generateAndSaveCampaignContent({
        campaignId: jobSetup?.campaignId!,
      });
      setHasAttemptedToGenerateAndSaveCampaignContent(true);
    }
    if (
      !hasAttemptedToGenerateAndSaveCampaignContent &&
      shouldAttemptCampaignOutreachGeneration &&
      jobSetup?.campaignId
    ) {
      generateAndSaveCampaignContentAPI();
    }
  }, [
    generateAndSaveCampaignContent,
    hasAttemptedToGenerateAndSaveCampaignContent,
    jobSetup?.campaignId,
    shouldAttemptCampaignOutreachGeneration,
  ]);

  if (isGeneratingCampaignContent || isFetchingCampaigns || isFetchingJobSetup) {
    return <Skeleton width="100%" height="160px" />;
  }

  if (shouldShowIncompleteCompanyPitchModal || shouldShowIncompleteJobPitchModal) {
    return (
      <Stack spacing={1}>
        <Subtitle2>Email outreach</Subtitle2>
        {shouldShowIncompleteCompanyPitchModal ? (
          <CompanyPitchIncompleteBanner />
        ) : (
          <JobPitchIncompleteBanner setDismissedJobPitchModal={setDismissedJobPitchModal} />
        )}
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Subtitle2>Email Campaigns</Subtitle2>
        <Button variant={ButtonVariant.Secondary} onClick={(): void => openCampaignDrawer({})}>
          + Create Campaign
        </Button>
      </Stack>

      {isSuccess && campaigns && campaigns.length > 0 && (
        <Box className="hide-scrollbar" display="flex" maxWidth="800px" sx={{ overflowX: "auto", flexWrap: "nowrap" }}>
          {campaigns.map(campaign => {
            return <CampaignCardV2 campaign={campaign} />;
          })}
        </Box>
      )}
    </>
  );
};

export default EmailCampaigns;
