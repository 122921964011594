const getAppUrl = (): string => {
  if (process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV === "staging") {
    return window.location.origin;
  }
  return process.env.REACT_APP_APP_URL || "https://app.dover.com";
};

const getAppDomain = (): string => {
  // since we already use the appurl constant, use that to get the domain by stripping out the scheme
  const appUrl = getAppUrl();
  const appUrlParts = appUrl.split("//");
  const appDomain = appUrlParts[appUrlParts.length - 1];
  return appDomain;
};

export const appConfig = {
  appUrl: getAppUrl(),
  leverRedirectURIPrefix: process.env.REACT_APP_LEVER_REDIRECT_URI_PREFIX || "https://app.dover.io",
  appDomain: getAppDomain(),
  apiUrl: process.env.REACT_APP_DOVER_API_URL || getAppUrl(),
  superApiUrl: process.env.REACT_APP_SUPERAPI_URL || "https://pop-iowa.superapi.cloud",
  superApiHeaders: { "X-Superapi-Host": "app.dover.com" },
};
