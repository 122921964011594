import { Role, useHasRole } from "components/RBAC";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { jobNavOptions, SOURCING_AUTOPILOT_NAV_OPTION } from "views/job/constants";
import { JobNavOption } from "views/job/types";

export const useJobNavOptions = (): JobNavOption[] => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);
  const isAdmin = useHasRole(Role.ADMIN);

  const showLegacySourcingTab = useLegacySourcingAutopilot || isAdmin;

  // const navOptions = [...(useNewJobNavOptions ? jobNavOptionsWithReferralsPage : jobNavOptions)];
  const navOptions = [...jobNavOptions];

  if (showLegacySourcingTab) {
    navOptions.push(SOURCING_AUTOPILOT_NAV_OPTION);
  }

  return navOptions;
};
